import { useTranslation } from 'next-i18next';

import { useProfile } from '../../../hooks';
import { SubscribeModal } from '../../Modals';

const MagazineCardModal = ({
  onCloseModal: handleCloseModal,
  onSubmit: handleSubmit,
  isLoading,
}: {
  onCloseModal: (value: boolean) => void;
  onSubmit: (values: { email: string; firstName: string; lastName: string; subscribeNewsletter: boolean }) => void;
  isLoading: boolean;
}) => {
  const { isLoggedIn } = useProfile();
  const { t } = useTranslation('common', {
    keyPrefix: 'magazineCard.modal',
  });

  const translations = {
    title: t('title'),
    description: !isLoggedIn ? t('description') : undefined,
    subscribe: t('subscribe'),
  };

  return (
    <SubscribeModal
      isLoading={isLoading}
      onCloseModal={handleCloseModal}
      onSubmit={handleSubmit}
      translations={translations}
    />
  );
};

export default MagazineCardModal;
