import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { CHANNEL, trackEvents } from '@boss/constants/shared';
import { IProduct } from '@boss/services';
import { IBasketLine } from '@boss/services/client';
import { IArticle, ISearchProduct, SimpleColor } from '@boss/types/b2b-b2c';

import { useBasket, useEventTracker } from '..';
import { ArticleIdsObject } from '../../components/ArticlesTable';
import { getArticleById, getTotalPriceArticles, mapBasketLine } from '../../utils';

interface Props {
  articles: IArticle[];
  selectedColor?: SimpleColor;
  product?: IProduct | ISearchProduct;
}

const getTrackingCategoryArray = (product: IProduct | ISearchProduct | undefined) => {
  if (product && 'trackingInfo' in product) {
    return product?.trackingInfo?.trackingCategories;
  } else if (product && Array.isArray(product.categories)) {
    return product?.categories[0].trackingCategories;
  }
  return [];
};

const getTrackingCategoryName = (product?: IProduct | ISearchProduct) => {
  if (product && 'trackingInfo' in product) {
    return product?.trackingInfo?.categoryName;
  } else if (product && Array.isArray(product.categories)) {
    return product?.categories[0].name;
  }
  return undefined;
};

const getTrackingCategoryId = (product?: IProduct | ISearchProduct) => {
  if (product && 'trackingInfo' in product) {
    return product?.trackingInfo?.categoryId;
  } else if (product && Array.isArray(product.categories)) {
    return product?.categories[0].id;
  }
  return undefined;
};

/**
 * Custom hook for adding items to the basket through an event
 *
 * usecase: onClick on a button
 */
export const useAddToBasket = ({ articles, selectedColor, product }: Props) => {
  const { t } = useTranslation(['product']);
  const { addBasketLines, isUpdateBasketLoading } = useBasket();

  const [hasError, setHasError] = useState(false);
  const [infoMessage, setInfoMessage] = useState<
    | {
        title: string;
        message: string;
      }
    | undefined
  >();
  const [selectedArticleIdsObject, setSelectedArticleIdsObject] = useState<ArticleIdsObject>({});
  const [hasArticlesSelected, setHasArticlesSelected] = useState(false);
  const { trackCustomEvent } = useEventTracker();

  useEffect(() => {
    const hasSelectedArticles = Object.entries(selectedArticleIdsObject).some(
      ([_, articleValues]) => articleValues.quantity > 0,
    );

    setHasArticlesSelected(hasSelectedArticles);
  }, [selectedArticleIdsObject]);

  /**
   * The main trigger functionality
   *
   * @param callback Extend the functionality for component specific state updates
   */
  const addToBasket = async ({ callback, isColorProduct }: { callback?: () => void; isColorProduct: boolean }) => {
    const trackingArticles: {
      item_id: string;
      item_name: string;
      price: number;
      quantity: number;
    }[] = [];

    if (Object.entries(selectedArticleIdsObject).length === 0) {
      setInfoMessage({
        title: t('productPanel.infoTitle'),
        message: t('productPanel.infoMessage.selectArticlesRequired'),
      });
      return false;
    }
    if (isColorProduct && !selectedColor) {
      setInfoMessage({
        title: t('productPanel.infoTitle'),
        message: t('productPanel.infoMessage.selectColorRequired'),
      });
      return false;
    }
    setInfoMessage(undefined);
    const lines = Object.entries(selectedArticleIdsObject).reduce((prev: IBasketLine[], [articleId, articleValues]) => {
      const article = getArticleById(articleId, articles);

      if (!article || articleValues.quantity < 1) {
        return prev;
      }

      trackingArticles?.push({
        item_id: article.id,
        item_name: article.name,
        price: articleValues.price ?? 0,
        quantity: articleValues.quantity,
      });

      return [...prev, mapBasketLine(article, articleValues, selectedColor)];
    }, []);

    if (!lines.length) {
      return;
    }

    const trackingProps = {
      item_id: product?.id,
      item_name: product?.name,
      affiliation: `${product?.locale} webshop`,
      coupon: product?.labels?.filter(label => label !== 'bestseller' && label !== 'ecocheques')[0],
      currency: 'EUR',
      item_brand: CHANNEL,
      item_category: getTrackingCategoryArray(product)?.[0],
      item_category2: getTrackingCategoryArray(product)?.[1],
      item_list_name: getTrackingCategoryName(product),
      item_list_id: getTrackingCategoryId(product),
      items: trackingArticles,
      value: getTotalPriceArticles(selectedArticleIdsObject, articles),
    };

    try {
      await addBasketLines(lines, !product?.crossSell?.length);
      setSelectedArticleIdsObject({});
      if (callback) {
        callback();
      }
      trackCustomEvent(trackEvents.ADD_TO_CART, trackingProps);
    } catch (e) {
      console.error(e);
      setHasError(true);
    }
  };

  useEffect(() => {
    setSelectedArticleIdsObject({});
  }, [selectedColor]);

  return {
    hasError,
    selectedArticleIdsObject,
    addToBasket,
    hasArticlesSelected,
    setSelectedArticleIdsObject,
    setHasError,
    setInfoMessage,
    infoMessage,
    isBasketUpdateLoading: isUpdateBasketLoading,
  };
};
