export const getBaseUrl = (locale: string, excludeLocale = false): string => {
  const HOST =
    process.env.VERCEL_ENV === 'production'
      ? process.env.VERCEL_PROJECT_PRODUCTION_URL ?? process.env.NEXT_PUBLIC_VERCEL_PROJECT_PRODUCTION_URL
      : process.env.VERCEL_URL ?? process.env.NEXT_PUBLIC_VERCEL_URL;

  if (locale === 'nl-NL' && process.env.NEXT_PUBLIC_HOST_COLORA_NL) {
    return `https://${process.env.NEXT_PUBLIC_HOST_COLORA_NL}`;
  }

  return `https://${HOST}/${excludeLocale ? '' : locale}`;
};
