import { useTranslation } from 'next-i18next';
import { SwiperProps } from 'swiper/react';

import { trackEvents } from '@boss/constants/shared';
import { IProduct } from '@boss/services';
import { Presence, Section } from '@boss/ui';

import { ProductCardWrapper } from '..';
import { useProductMap } from '../../hooks';
import CarouselProductsWrapper from '../CarouselProductsWrapper';

/**
 * Represents the carousel for the new products B2B
 * @property {string} className - The overwrite classname for the container element
 * @property {SwiperProps} options - The options for the carousel
 * @property {string} backgroundColor - the overwrite background color for the section
 * @property {string} testId - testId given for the section
 * @property {IProduct[]} mostRecentProducts - Array of most recent product objects
 */

type Props = {
  className?: string;
  options?: SwiperProps;
  backgroundColor?: string;
  testId?: string;
  mostRecentProducts?: IProduct[];
};

const NewProducts = ({
  options,
  className,
  backgroundColor,
  mostRecentProducts,
  testId = 'new-products-section',
}: Props) => {
  const { mapProductComponent } = useProductMap();
  const { t } = useTranslation('common');

  return (
    <Presence className={className} id="new-products-presence" visible={!!mostRecentProducts?.length}>
      {!!mostRecentProducts?.length && (
        <Section
          backgroundColor={backgroundColor}
          content={
            <CarouselProductsWrapper
              id="related-products-carousel"
              options={options}
              products={mostRecentProducts}
              title={t('product.new') ?? undefined}
            >
              {mostRecentProducts.map((product, index) => {
                const props = mapProductComponent(product);

                const _trackInfo = {
                  ...props.trackInfo,
                  customEvent: trackEvents.SELECT_ITEM,
                  index,
                  item_list_name: 'new products',
                  item_id: product.id,
                  item_name: product.name,
                };

                return product ? <ProductCardWrapper {...props} key={product.id} trackInfo={_trackInfo} /> : null;
              })}
            </CarouselProductsWrapper>
          }
          testId={testId}
        />
      )}
    </Presence>
  );
};

export default NewProducts;
