import { ButtonProps } from '@boss/types/b2b-b2c';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import ButtonOrAnchor from '../_Button';

const ButtonStyles = cva('flex items-center justify-center px-6 py-1 button gap-3', {
  variants: {
    type: {
      primary: 'bg-pillBlue rounded-navButton text-white hover:bg-white hover:text-pillBlue',
      secondary: 'bg-ctaBanner rounded-navButton text-white hover:bg-white hover:text-ctaBanner',
      tertiary: 'bg-navButton rounded-navButton font-bold py-2',
      link: 'text-textColor font-bold pl-0',
      mobileMenu: 'rounded-navButton bg-navButton text-textColor',
    },
  },
});

const Button = ({
  alternativeText,
  anchorClassName,
  className,
  fileToDownload,
  hideLabel,
  href,
  icon,
  iconPosition,
  label,
  labelClassName,
  onClick,
  onKeyDown,
  submitButton,
  testId,
  type,
  trackInfo,
}: ButtonProps) => (
  <ButtonOrAnchor
    alternativeText={alternativeText}
    anchorClassName={anchorClassName}
    className={twMerge(ButtonStyles({ type }), className)}
    fileToDownload={fileToDownload}
    href={href}
    onClick={onClick}
    onKeyDown={onKeyDown}
    submitButton={submitButton}
    testId={testId}
    trackInfo={trackInfo}
  >
    {icon && iconPosition === 'left' && <FontAwesomeIcon icon={icon} />}
    {!hideLabel && label && <span className={twMerge('w-full', labelClassName)}>{label}</span>}
    {icon && iconPosition === 'right' && <FontAwesomeIcon icon={icon} width={16} />}
  </ButtonOrAnchor>
);

export default Button;
