import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { Image, Link } from '..';

type Props = {
  title: string;
  description: string;
  slug: string;
  imageUrl: string;
  imageAlt: string;
  className?: string;
  translations?: {
    linkLabel: string;
  };
  whitespace?: 'ratio' | 'none';
  style?: React.CSSProperties;
  variant?: 'normal' | 'wide' | 'image-on-left' | 'image-on-right';
};

const textStyles = cva('w-full p-4 md:col-span-2 md:px-12 md:py-5 md:px-8', {
  variants: {
    variant: {
      ratio: '',
      none: 'h-auto',
    },
    isWide: {
      true: 'md:flex md:items-center md:h-full',
    },
  },
});

const imageStyles = cva('w-full overflow-hidden bg-gray-100', {
  variants: {
    variant: {
      ratio: 'aspect-[16/12]',
      none: 'aspect-[16/8]',
    },
    isWide: {
      true: 'md:h-full',
    },
  },
});

const ContentTile = ({
  imageUrl,
  imageAlt,
  title,
  description,
  slug,
  className,
  translations,
  whitespace = 'ratio',
  style,
  variant = 'normal',
}: Props) => {
  return (
    <Link className={twMerge('relative block h-full w-full')} href={slug}>
      <div
        className={twMerge(
          'border-1 rounded-t-brand rounded-r-brand border-gray-light grid h-full grid-cols-1 grid-rows-[max-content_auto_4rem] overflow-hidden bg-white shadow-sm',
          variant === 'wide' ? 'md:grid md:h-full md:grid-cols-2 md:grid-rows-1' : 'md:min-h-60',
          className,
        )}
        style={style}
      >
        <div className={imageStyles({ variant: whitespace, isWide: variant === 'wide' })}>
          {imageUrl && <Image alt={imageAlt} className="h-full w-full object-cover" src={imageUrl} width={500} />}
        </div>
        <div className="h-full">
          <div className={textStyles({ variant: whitespace, isWide: variant === 'wide' })}>
            <div className="flex flex-col gap-2">
              <span className="h3 hyphens-auto">{title}</span>
              <span>{description}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-5 right-10 md:bottom-5 md:right-10">
        {translations?.linkLabel && (
          <span className="text-blue flex items-center gap-2 py-2">
            {translations?.linkLabel} <FontAwesomeIcon icon={faArrowRight} />
          </span>
        )}
      </div>
    </Link>
  );
};

export default ContentTile;
