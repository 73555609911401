import { useTranslation } from 'next-i18next';
import { SwiperProps } from 'swiper/react';

import { trackEvents } from '@boss/constants/shared';
import { Presence, Section } from '@boss/ui';

import { CarouselProductsWrapper, ProductCardWrapper } from '..';
import { useProductsById } from '../../client-queries';
import { useProductMap, useRecentViewedProducts } from '../../hooks';

type Props = {
  className?: string;
  options?: SwiperProps;
  backgroundColor?: string;
  testId?: string;
  trackInfo?: {
    item_category: string;
    item_category2: string;
  };
};

const RecentlyViewedProducts = ({
  options,
  className,
  backgroundColor,
  testId = 'recently-viewed-products-section',
  trackInfo,
}: Props) => {
  const { viewedProductIds } = useRecentViewedProducts();
  const { data: recentProducts } = useProductsById(viewedProductIds);
  const { mapProductComponent } = useProductMap();
  const { t } = useTranslation('common');

  return (
    <Presence className={className} id="recently-viewed-products-presence" visible={!!recentProducts?.length}>
      {!!recentProducts?.length && (
        <Section
          backgroundColor={backgroundColor}
          content={
            <CarouselProductsWrapper
              id="related-products-carousel"
              options={options}
              products={recentProducts}
              title={t('product.recently.visited') ?? undefined}
            >
              {recentProducts.map((product, index) => {
                const props = mapProductComponent(product);

                const _trackInfo = {
                  ...props.trackInfo,
                  customEvent: trackEvents.SELECT_ITEM,
                  ...trackInfo,
                  item_id: product.id,
                  item_name: product.name,
                  index,
                  item_list_name: 'recently viewed',
                };

                return product ? <ProductCardWrapper {...props} key={product.id} trackInfo={_trackInfo} /> : null;
              })}
            </CarouselProductsWrapper>
          }
          testId={testId}
        />
      )}
    </Presence>
  );
};

export default RecentlyViewedProducts;
