import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useLockedBody } from 'usehooks-ts';

import { IAccount, IContact } from '@boss/services/client';
import { FormRefType } from '@boss/types/b2b-b2c';
import { AddressBlock } from '@boss/ui';
import { formatPhoneNumber } from '@boss/utils';

import { DynamicForm } from '../../../components';
import { FormValues } from '../../../components/DynamicForm';
import { FormField } from '../../../components/Mappers/FormFieldMapper';
import { useFormField } from '../../../hooks';
import { isB2b } from '../../../utils';

type Address = IAccount['addresses'][0];
type Addresstype = 'invoice' | 'delivery' | 'general';

type Props = {
  account: IAccount;
  onUpdate?: (id: string, values: FormValues) => void;
  hideTitles?: boolean;
  addressesToShow?: Addresstype[];
  noShadow?: boolean;
  className?: string;
  contactInfo?: IContact;
  hideEdit?: boolean;
  showNewAddressForm?: boolean;
  scope?: string;
  formRef?: React.Ref<{ validateAndSubmitForm: () => FormRefType }>;
};

type NameProps = { firstname: string; lastname: string; className?: string };

const Name = ({ firstname, lastname, className }: NameProps) => (
  <h5 className={twMerge('pb-5 font-bold', className)}>
    {firstname} {lastname}
  </h5>
);

const getDeliveryAddress = (foundAccountDeliveryAddress: Address | undefined, deliveryAddr: Address) => {
  return foundAccountDeliveryAddress ?? deliveryAddr;
};

const ContactInfoBlocks = ({
  account,
  contactInfo,
  onUpdate: handleUpdate,
  hideTitles,
  addressesToShow = [],
  noShadow,
  className,
  showNewAddressForm,
  scope,
  formRef,
  hideEdit = false,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Props) => {
  const [showModal, setShowmodal] = useState({
    info: false,
    deliveryAddr: false,
    invoiceAddr: false,
  });

  const {
    name: nameField,
    street: streetField,
    streetnumber: streetnumberField,
    bus: busField,
    zipcode: zipcodeField,
    city: cityField,
    country: countryField,
    kvknumber: kvknumberField,
    address: addressField,
    getConvertedCountry,
    vatnumber: vatnumberField,
    companyname: companynameField,
  } = useFormField({ customOverwrite: { name: { disabled: false } } });

  useLockedBody(showModal.deliveryAddr || showModal.info || showModal.invoiceAddr, 'root');

  const handleSetShowModal = (type: keyof typeof showModal, value: boolean) => {
    setShowmodal({
      ...showModal,
      [type]: value,
    });
  };

  const { t } = useTranslation('account');
  const invoiceAddr = account.addresses.find(adr => adr.type === 'invoice') ?? null;
  const deliveryAddr = account.addresses.find(adr => adr.type === 'delivery') ?? invoiceAddr ?? null;
  const smallFormColStyling = 'md:col-span-1';

  const {
    accountnumber,
    accountcode,
    accountinfo: { name },
    accountinfo: { firstname, lastname },
  } = account;

  const nameObj = { firstname, lastname };

  const updateAccount = (id: keyof typeof showModal, values: FormValues) => {
    handleSetShowModal(id, false);
    if (handleUpdate) {
      handleUpdate(id, values);
    }
  };

  const setInitialValues = (value: string | undefined, noInitialValues: boolean) => {
    return value && value !== '' && !noInitialValues ? value : undefined;
  };

  const generateFieldName = (scope: string | undefined, fieldName: string): string => {
    return fieldName;
  };

  const getDefaultAddressFields = (address: Address | undefined, noValuesInitially = false): FormField[] => {
    return [
      {
        ...addressField,
        required: false,
        scope: scope,
        fields: [
          {
            ...streetField,
            required: true,
            name: generateFieldName(scope, 'street'),
            initialValue: setInitialValues(address?.street, noValuesInitially),
          },
          {
            ...streetnumberField,
            required: true,
            initialValue: setInitialValues(address?.streetnumber, noValuesInitially),
            name: generateFieldName(scope, 'streetnumber'),
            colStyle: smallFormColStyling,
          },
          {
            ...busField,
            initialValue: setInitialValues(address?.postbox, noValuesInitially),
            name: generateFieldName(scope, 'postbox'),
            colStyle: smallFormColStyling,
          },
          {
            ...zipcodeField,
            required: true,
            initialValue: setInitialValues(address?.zipcode, noValuesInitially),
            name: generateFieldName(scope, 'zipcode'),
            colStyle: smallFormColStyling,
          },
          {
            ...cityField,
            required: true,
            initialValue: setInitialValues(address?.city, noValuesInitially),
            name: generateFieldName(scope, 'city'),
          },
          {
            ...countryField,
            required: true,
            initialValue: setInitialValues(getConvertedCountry('country', address?.country), noValuesInitially),
            name: generateFieldName(scope, 'country'),
          },
        ],
      },
    ];
  };

  const getBillingFields = (address: Address | undefined) => {
    return [
      {
        ...vatnumberField,
        required: false,
        initialValue: setInitialValues(address?.vatnumber || account.billinginfo.vatnumber, false),
      },
      {
        ...companynameField,
        required: false,
        initialValue: setInitialValues(address?.companyname || account.billinginfo.companyname, false),
      },
    ];
  };

  const showAddress = (type: 'invoice' | 'delivery' | 'general') =>
    !addressesToShow?.length || addressesToShow.includes(type);

  const deliveryOptions = showNewAddressForm ? { validateOnChange: true } : {};

  const foundAccountDeliveryAddress = account.addresses.find(a => a.type === 'delivery');

  const forms = {
    basic: (
      <DynamicForm
        buttonProps={{ label: t('adjust') ?? '' }}
        fields={[
          {
            name: 'firstname',
            type: 'input',
            initialValue: contactInfo?.firstname,
          },
          {
            name: 'lastname',
            type: 'input',
            initialValue: contactInfo?.lastname,
          },
          {
            name: 'phonenumber',
            type: 'phone',
            initialValue: contactInfo?.officephonenumber,
            required: false,
          },
          {
            name: 'mobilephonenumber',
            type: 'phone',
            initialValue: contactInfo?.mobilephonenumber,
            required: false,
          },
          {
            name: 'email',
            type: 'input',
            initialValue: contactInfo?.email,
          },
        ]}
        id="basic-form"
        onSubmit={v => updateAccount('info', v)}
        ref={formRef}
        variant="light"
      />
    ),
    deliveryAddress: deliveryAddr && (
      <DynamicForm
        buttonProps={{ label: showNewAddressForm ? t('useValues') : t('adjust') ?? '' }}
        fields={getDefaultAddressFields(getDeliveryAddress(foundAccountDeliveryAddress, deliveryAddr))}
        id="delivery-address-form"
        onSubmit={v => updateAccount('deliveryAddr', v)}
        options={deliveryOptions}
        ref={formRef}
        showSubmitButton={!showNewAddressForm}
        variant="light"
      />
    ),
    invoiceAddress: invoiceAddr && (
      <DynamicForm
        buttonProps={{ label: t('adjust') ?? '' }}
        fields={[
          ...(isB2b ? [nameField] : []),
          ...getBillingFields(invoiceAddr),
          ...getDefaultAddressFields(invoiceAddr),
          kvknumberField,
        ]}
        id="invoice-address-form"
        onSubmit={v => updateAccount('invoiceAddr', v)}
        ref={formRef}
        showSubmitButton={!showNewAddressForm}
        variant="light"
      />
    ),
  };

  const buildAddressString = (address: Address, id: string) => {
    const addressKeys: (keyof Address)[][] = [['street', 'streetnumber', 'postbox'], ['zipcode', 'city'], ['country']];

    return addressKeys.map((addressLine, index) => (
      <span key={`${id}-${index}`}>{addressLine.map(key => address?.[key] ?? '')?.join(' ')}</span>
    ));
  };

  return (
    <div className={twMerge('flex flex-col gap-x-3 lg:grid lg:grid-cols-3', className)}>
      {showAddress('general') && contactInfo && (
        <AddressBlock
          form={forms.basic}
          hideEdit={isB2b || hideEdit}
          noShadow={noShadow}
          noTitle={hideTitles}
          onSetShowModal={value => handleSetShowModal('info', value)}
          showModal={showModal['info']}
          submitText={t('submit') ?? ''}
          title={t('info.gegevens') ?? ''}
        >
          <div className="flex flex-col">
            <Name firstname={contactInfo.firstname ?? ''} lastname={contactInfo.lastname ?? ''} />
            <span>{contactInfo.email}</span>
            {contactInfo.officephonenumber && <span>{`Tel: ${formatPhoneNumber(contactInfo.officephonenumber)}`}</span>}
            {contactInfo.mobilephonenumber && <span>{`GSM: ${formatPhoneNumber(contactInfo.mobilephonenumber)}`}</span>}
            <span>{`${t('info.accountNumber')}: ${isB2b ? accountnumber : accountcode}`}</span>
          </div>
        </AddressBlock>
      )}
      {showAddress('delivery') && deliveryAddr && (
        <>
          {!showNewAddressForm ? (
            <AddressBlock
              form={forms.deliveryAddress}
              hideEdit={isB2b || hideEdit}
              noShadow={noShadow}
              noTitle={hideTitles}
              onSetShowModal={value => handleSetShowModal('deliveryAddr', value)}
              showModal={showModal['deliveryAddr']}
              submitText={t('submit') ?? ''}
              title={t('info.delivery') ?? ''}
            >
              {name ? (
                <Name className={showNewAddressForm ? 'pb-0' : ''} firstname={name} lastname="" />
              ) : (
                <Name {...nameObj} />
              )}
              {!showNewAddressForm && (
                <div className="flex flex-col">{buildAddressString(deliveryAddr, 'deliveryAddr')}</div>
              )}
            </AddressBlock>
          ) : (
            <div className="mb-5">{forms.deliveryAddress}</div>
          )}
        </>
      )}
      {showAddress('invoice') && invoiceAddr && (
        <>
          {!showNewAddressForm ? (
            <AddressBlock
              form={forms.invoiceAddress}
              hideEdit={isB2b || hideEdit}
              noShadow={noShadow}
              noTitle={hideTitles}
              onSetShowModal={value => handleSetShowModal('invoiceAddr', value)}
              showModal={showModal['invoiceAddr']}
              submitText={t('submit') ?? ''}
              title={t('info.invoice') ?? ''}
            >
              <div className="flex flex-col">
                {account.billinginfo.companyname && <span>{account.billinginfo.companyname}</span>}
                {name ? <Name firstname={name} lastname="" /> : <Name {...nameObj} />}
                {buildAddressString(invoiceAddr, 'invoiceAddr')}
              </div>
            </AddressBlock>
          ) : (
            <div className="mb-5">{forms.invoiceAddress}</div>
          )}
        </>
      )}
    </div>
  );
};

export default ContactInfoBlocks;
