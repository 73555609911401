import { SimpleColor } from '@boss/types/b2b-b2c';
import {
  IconDefinition,
  faBookmark,
  faChevronRight,
  faClock,
  faInputText,
  faPalette,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ColorsGrid, ColorsGridSkeleton, Divider, Presence, SearchBar } from '../../';

export type FlyoutTranslations = {
  noResults: string;
  recentlyViewed: string;
  searchColorGroup: string;
  searchFavorites: string;
  searchLabel: string;
  searchPlaceholder: string;
  showLess: string;
  showMore: string;
  trending?: string;
};

type Props = {
  className?: string;
  favoriteColors?: SimpleColor[];
  hasSearched?: boolean;
  onChangeColorClick: (SimpleColor: SimpleColor) => void;
  onChangeGroupClick: () => void;
  onSearch: () => void;
  recentColors?: SimpleColor[];
  searchColors?: SimpleColor[];
  searchLoading?: boolean;
  searchValue?: string;
  setSearchValue: (val: string) => void;
  showMoreAmountSearch?: number;
  translations: FlyoutTranslations;
  selectedColor?: SimpleColor;
  variant?: 'primary' | 'secondary';
};

const Title = ({ children, icon }: { children: ReactNode; icon: IconDefinition }) => (
  <div className="flex items-center gap-2">
    <FontAwesomeIcon icon={icon} />
    <span className="small font-bold">{children}</span>
  </div>
);

const ColorsRow = ({
  icon,
  translations,
  colors,
  className,
  onClick,
  title,
  variant = 'primary',
}: {
  title: string;
  translations: FlyoutTranslations;
  icon: IconDefinition;
  colors: SimpleColor[];
  className?: string;
  onClick: (color: SimpleColor) => void;
  variant?: 'primary' | 'secondary';
}) => (
  <Fragment>
    <Divider />
    <div className="flex flex-col gap-2">
      <Title icon={icon}>{title}</Title>
      <ColorsGrid
        colors={colors}
        gridClassName={className}
        onChangeColorClick={onClick}
        translations={translations}
        variant={variant}
      />
    </div>
  </Fragment>
);

const ColorFlyout = ({
  onChangeGroupClick,
  className,
  translations,
  recentColors,
  favoriteColors,
  onSearch,
  searchValue,
  setSearchValue,
  onChangeColorClick,
  searchColors: initialSearchColors = [],
  searchLoading,
  hasSearched,
  showMoreAmountSearch = 8,
  variant = 'primary',
}: Props) => {
  const [showMoreColorsSearch, setShowMoreColorsSearch] = useState(false);

  const gridClassName = 'gap-y-2 md:gap-y-2';
  const hasShowMoreSearch = initialSearchColors?.length > showMoreAmountSearch;
  const searchColors = showMoreColorsSearch
    ? initialSearchColors
    : [...initialSearchColors]?.splice(0, showMoreAmountSearch);

  return (
    <div className={twMerge('-mx-8 flex flex-col gap-3 px-8 py-5', className)}>
      <div className="flex flex-col gap-2">
        <Title icon={faInputText}>{translations.searchLabel}</Title>
        <SearchBar
          className="border-border-light border-1 bg-white py-2"
          onSearch={onSearch}
          searchLabel={translations.searchPlaceholder}
          setSearchValue={setSearchValue}
          type="secondary"
          value={searchValue}
        />
        <Presence
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ height: 0, opacity: 0, marginTop: '-0.5rem' }}
          id="color-flyout-search-colors-presence"
          initial={{ opacity: 0, height: 'auto' }}
          isLoading={searchLoading}
          loader={<ColorsGridSkeleton className="mt-2 md:gap-4" hasTitle={false} />}
          visible={hasSearched}
        >
          <ColorsGrid
            className="mt-2 md:gap-4"
            colors={searchColors}
            gridClassName={gridClassName}
            handleShowMore={() => setShowMoreColorsSearch(val => !val)}
            hasShowMore={hasShowMoreSearch}
            onChangeColorClick={onChangeColorClick}
            showMore={showMoreColorsSearch}
            translations={translations}
            variant={variant}
          />
        </Presence>
      </div>
      {!!recentColors?.length && (
        <ColorsRow
          className={gridClassName}
          colors={recentColors}
          icon={faBookmark}
          onClick={onChangeColorClick}
          title={translations.recentlyViewed}
          translations={translations}
          variant={variant}
        />
      )}
      {!!favoriteColors?.length && (
        <ColorsRow
          className={gridClassName}
          colors={favoriteColors}
          icon={faClock}
          onClick={onChangeColorClick}
          title={translations.searchFavorites}
          translations={translations}
          variant={variant}
        />
      )}
      <Divider />
      <button className="flex items-center justify-between" onClick={() => onChangeGroupClick()}>
        <Title icon={faPalette}>{translations.searchColorGroup}</Title>
        <FontAwesomeIcon className="text-xs" icon={faChevronRight} />
      </button>
    </div>
  );
};

export default ColorFlyout;
