type ErrorResponse = {
  status: number;
  conflictData?: {
    errors?: Record<string, string>;
  };
};

export const getConflictErrorMessage = (error: unknown, errorKey: string): string | null => {
  if (
    error &&
    typeof error === 'object' &&
    'status' in error &&
    error.status === 409 &&
    'conflictData' in error &&
    error.conflictData &&
    typeof error.conflictData === 'object' &&
    'errors' in error.conflictData &&
    error.conflictData.errors &&
    typeof error.conflictData.errors === 'object' &&
    errorKey in error.conflictData.errors
  ) {
    return (error as ErrorResponse).conflictData?.errors?.[errorKey] ?? null;
  }
  return null;
};
