import { faMapPin, faStore, faTruck } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'next-i18next';
import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

import { useRouter } from '@boss/hooks';
import { IStore } from '@boss/services';
import { DeliveryOption, Link } from '@boss/ui';

import { Stock } from '../../hooks';
import { homeDeliverySlugs } from '../../utils';

type Props = {
  className?: string;
  onShopDeliveryClick: () => void;
  stockEnabled?: boolean;
  stock: Stock;
  savedStore?: IStore;
};

enum stockStatus {
  AVAILABLE = 'available',
  LOW_STOCK = 'limited',
  NOT_AVAILABLE = 'outOfStock',
}

const statusColorMap: { [key: string]: ComponentProps<typeof DeliveryOption>['statusColor'] } = {
  available: 'success',
  limited: 'default',
  outOfStock: 'error',
};

const DeliveryPromises = ({ className, onShopDeliveryClick, stockEnabled, stock, savedStore }: Props) => {
  const { locale } = useRouter();
  const { t } = useTranslation(['product', 'stores']);
  const storeFinderTranslations: Record<string, string> = t('storeFinder', { returnObjects: true, ns: 'stores' });

  const buildDisclaimerLabel = () => {
    const disclaimerLabelObject: { disclaimerLink: string; disclaimer: string } = t('productPanel.homeDelivery', {
      returnObjects: true,
    });

    if (!disclaimerLabelObject) {
      return null;
    }

    const { disclaimerLink, disclaimer } = disclaimerLabelObject;

    if (disclaimerLink) {
      return (
        <span className="caption">
          {disclaimer}{' '}
          <Link className="caption underline" href={homeDeliverySlugs?.[locale]}>
            {disclaimerLink}
          </Link>
        </span>
      );
    }

    return (
      <Link className="caption" href={homeDeliverySlugs?.[locale]}>
        {disclaimer}
      </Link>
    );
  };

  const getStoreStockStatus = (store: IStore | undefined | null) => {
    if (store && stock && stockEnabled) {
      const status: keyof typeof stockStatus = stock[store.coloradepotid];

      return stockStatus[status];
    }

    return null;
  };

  const storeStockStatus = getStoreStockStatus(savedStore);

  return (
    <div className={twMerge('flex flex-col gap-4 md:grid md:grid-cols-2', className)}>
      <DeliveryOption
        disclaimer={buildDisclaimerLabel()}
        icon={faTruck}
        key="homeDelivery-delivery-option"
        title={t('productPanel.homeDelivery.title', { ns: 'product' })}
      />
      <DeliveryOption
        button={
          stockEnabled
            ? {
                onClick: onShopDeliveryClick,
                icon: faMapPin,
                label: t('productPanel.shopDelivery.link', { ns: 'product' }),
              }
            : undefined
        }
        icon={faStore}
        key="shopDelivery-delivery-option"
        status={storeStockStatus ? storeFinderTranslations[storeStockStatus] : undefined}
        statusColor={storeStockStatus ? statusColorMap[storeStockStatus] : undefined}
        title={savedStore ? savedStore.name : t('productPanel.shopDelivery.title', { ns: 'product' })}
      />
    </div>
  );
};

export default DeliveryPromises;
