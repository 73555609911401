import { Nullable } from '@boss/types/b2b-b2c';
import { VariantProps, cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends VariantProps<typeof GridStyles> {
  content: ReactNode; // TODO: Convert to children
  title?: ReactNode;
  description?: ReactNode;
  className?: Nullable<string>;
  anchorId?: string;
  backgroundColor?: string;
  testId?: string;
  id?: string;
  cta?: ReactNode;
  ctaPosition?: 'bottom-right' | 'bottom-left';
  titleClassName?: string;
}

const SectionStyle = cva('py-12 scroll-mt-15', {
  variants: {
    backgroundColor: {
      true: 'relative break-before break-after before:-z-base after:-z-base',
    },
  },
});
const oneColumnStyle = 'grid-cols-1';

const GridStyles = cva('grid gap-4', {
  variants: {
    displayAs: {
      '1-column': oneColumnStyle,
      '2-columns': 'grid-cols-1 md:grid-cols-2',
      '2-columns-left-smaller': oneColumnStyle,
      '2-columns-right-smaller': oneColumnStyle,
      '2-columns-colored': 'grid-cols-1 md:grid-cols-2',
      '3-columns': 'grid-cols-1 lg:grid-cols-3',
      '4-columns': 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-4',
      'promotion-banner': oneColumnStyle,
      carousel: oneColumnStyle,
      hero: oneColumnStyle,
      widget: oneColumnStyle,
      'colora-store-finder': 'grid-cols-1 lg:grid-cols-5',
      '2-columns-with-image-out-of-bounds': oneColumnStyle,
      '2-columns-quote': oneColumnStyle,
      '2-columns-33-right': 'grid-cols-1 md:grid-cols-3 md:[&>*:nth-child(odd)]:col-span-2 gap-10',
      '2-columns-33-left': 'grid-cols-1 md:grid-cols-3 gap-10 md:[&>*:nth-child(even)]:col-span-2',
      'images-with-product-pointers': oneColumnStyle,
      'link-tiles': oneColumnStyle,
      tabs: oneColumnStyle,
      'tabs-small': oneColumnStyle,
      timeline: oneColumnStyle,
      'promotion-banner-switched': oneColumnStyle,
    },
  },
});

const CtaStyles = cva('flex pt-12 w-fit', {
  variants: {
    ctaPosition: {
      'bottom-right': 'justify-end',
      'bottom-left': '',
    },
  },
});

const Section = ({
  content,
  title,
  description,
  displayAs,
  className,
  anchorId,
  backgroundColor,
  id,
  testId,
  cta,
  ctaPosition = 'bottom-left',
  titleClassName,
}: Props) => {
  const outOfBoundSection = displayAs === '2-columns-with-image-out-of-bounds';

  const descriptionSection =
    title || description ? (
      <div className="flex flex-col">
        {title && <div className={twMerge('max-w-3/5', titleClassName)}>{title}</div>}
        {description && <div className="md:max-w-7/10 mb-4">{description}</div>}
      </div>
    ) : null;

  if (outOfBoundSection) {
    return (
      <section className={twMerge(SectionStyle({ backgroundColor: !!backgroundColor }), className)} id={anchorId}>
        {descriptionSection}
        {content}
      </section>
    );
  }

  return (
    <section
      className={twMerge(
        SectionStyle({ backgroundColor: !!backgroundColor }),
        backgroundColor,
        backgroundColor ? `before:${backgroundColor}` : '',
        backgroundColor ? `after:${backgroundColor}` : '',
        className,
      )}
      data-testid={testId}
      id={anchorId ?? id}
    >
      {descriptionSection}
      {displayAs ? <div className={twMerge(GridStyles({ displayAs }))}>{content}</div> : content}
      {cta && <div className={CtaStyles({ ctaPosition })}>{cta}</div>}
    </section>
  );
};

export default Section;
