import * as Sentry from '@sentry/nextjs';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

import { CHANNEL } from '@boss/constants/shared';
import { useRouter } from '@boss/hooks';
import { IColorGroup } from '@boss/services';
import { Color, SimpleColor } from '@boss/types/b2b-b2c';

import {
  fetchAllColorGroups,
  fetchColorByColorId,
  fetchColorsByColorCodesAndProductId,
  fetchColorsByColorTypeGroup,
  fetchColorsByGroupCodeAndProductId,
  fetchRelatedColors,
  searchColor,
} from './connector';
import { mapNextToContentfulLocale } from '../../utils/localeMapper';

import { colorKeys } from '.';

const useColorGroups = (enabled = true) => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  const res = useQuery<IColorGroup[] | undefined>(
    colorKeys.allColorGroups(locale),
    async () => await fetchAllColorGroups(),
    {
      enabled: !!enabled,
    },
  );

  return {
    ...res,
    data: res.data?.map(group => ({
      ...group,
      name: t(`colors.${group.code.toLowerCase()}`, { ns: 'common' }),
    })),
  };
};

const useRelatedColors = (colorCode: string | undefined) => {
  const { locale } = useRouter();
  const nextLocale = mapNextToContentfulLocale(locale);

  return useQuery<Color[] | null | undefined>(
    colorKeys.relatedColors(nextLocale, colorCode),
    async () => {
      return await fetchRelatedColors({ colorCode, locale: nextLocale });
    },
    {
      enabled: !!colorCode,
    },
  );
};

const useColorsByGroup = ({
  groupCode,
  productId,
  skuId,
}: {
  groupCode?: string;
  productId?: string;
  skuId?: string;
}) => {
  const { locale } = useRouter();

  return useQuery<Color[]>(
    colorKeys.colorsByGroupCode({ locale, code: groupCode, productId, skuId }),
    async () => {
      const colors: Color[] = await fetchColorsByGroupCodeAndProductId({ locale, groupCode, productId, skuId });

      return colors;
    },
    {
      enabled: !!groupCode,
    },
  );
};

const useColorsByColorCodes = (colorCodes: string[], productId?: string, enabled?: boolean) => {
  const { locale } = useRouter();

  return useQuery<SimpleColor[] | undefined>(
    colorKeys.colorsByColorCodes(locale, colorCodes, productId),
    async () => {
      const colors: SimpleColor[] = await fetchColorsByColorCodesAndProductId(locale, colorCodes, productId);

      return colors;
    },
    {
      enabled: !!colorCodes.length && enabled,
    },
  );
};

const useColorByColorId = (colorId = '') => {
  const { locale } = useRouter();

  return useQuery({
    queryKey: colorKeys.colorByColorId(locale, colorId),
    queryFn: async () => {
      return await fetchColorByColorId(colorId, locale, CHANNEL);
    },
    enabled: !!colorId,
    onError: e => {
      console.error(e);
      Sentry.captureException(e, {
        tags: {
          type: 'Fetch client side stock by base',
        },
      });
    },
  });
};

const useSearchColor = ({
  value,
  productId,
  skuId,
  enabled = true,
  colortypegroup,
}: {
  value?: string;
  productId?: string;
  skuId?: string;
  enabled?: boolean;
  colortypegroup?: string;
}) => {
  const { locale } = useRouter();

  return useQuery<Color[]>(
    colorKeys.colorSearch({ locale, value, productId, skuId, colortypegroup }),
    async () => {
      const colors: Color[] = await searchColor({
        locale,
        value,
        productId,
        skuId,
        colortypegroup: productId ? colortypegroup : undefined,
      });

      return colors;
    },
    {
      enabled,
    },
  );
};

const useColorByColorTypeGroup = (base: string, productId: string, enabled = true) => {
  const { locale } = useRouter();

  return useQuery({
    queryKey: colorKeys.colortypeGroup(base, locale, productId),
    queryFn: async () => await fetchColorsByColorTypeGroup(base, productId, locale),
    enabled: !!base && !!productId && enabled,
    onError: e => {
      console.error(e);
      Sentry.captureException(e, {
        tags: {
          type: 'Fetch client side colors by colorType group',
        },
      });
    },
  });
};

export {
  useColorByColorTypeGroup,
  useColorsByColorCodes,
  useColorByColorId,
  useColorGroups,
  useColorsByGroup,
  useSearchColor,
  useRelatedColors,
};
