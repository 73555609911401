import { ImageProps, Nullable, Theme } from '@boss/types/b2b-b2c';
import { isAnchorLink } from '@boss/utils';
import { IconDefinition, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Button from '../Button';
import Image from '../Image';
import Link from '../Link';

type Variant = 'primary' | 'secondary' | 'tertiary' | 'service';
type CornerVariant = 'primary' | 'secondary' | 'tertiary' | 'service' | 'noCorners';

export type Props = {
  className?: string;
  icon?: Nullable<IconDefinition>;
  image?: ImageProps;
  link: {
    href: string;
    label?: string;
  };
  noRoundedCorners?: boolean;
  text: ReactNode;
  theme?: Theme;
  variant?: Variant;
  alignment?: 'right' | 'left';
};

export type PrimaryProps = {
  variant?: Variant;
  cornerVariant: CornerVariant;
  image?: ImageProps;
  text: ReactNode;
  icon?: Nullable<IconDefinition>;
  link: {
    href: string;
    label?: string;
  };
  alignment?: 'right' | 'left';
};

export type SecondaryProps = {
  variant?: Variant;
  image?: ImageProps;
  text: ReactNode;
  className?: string;
  icon?: Nullable<IconDefinition>;
};

const CornerStyle = cva('relative block', {
  variants: {
    variant: {
      primary: 'min-h-full rounded-r-30 rounded-tl-30 xl:rounded-l-50 xl:rounded-br-50 xl:rounded-tr-none',
      secondary: 'min-h-full rounded-r-30 rounded-bl-30 xl:rounded-r-50 xl:rounded-bl-50',
      tertiary:
        'h-auto rounded-r-35 rounded-bl-35 rounded-tl-none rounded-tr-35 lg:rounded-tl-none lg:rounded-l-35 lg:rounded-br-35 lg:rounded-tr-35',
      service:
        'min-h-full flex rounded-r-md rounded-bl-md rounded-tl-md rounded-tr-md md:rounded-bl-50 md:rounded-br-50 md:rounded-tl-none md:rounded-tr-50',
      noCorners: 'min-h-full',
    },
  },
});

const TextStyle = cva('relative sm:px-6 px-3 xl:px-8 text-white z-2', {
  variants: {
    variant: {
      primary: 'text-xl md:text-2x lg:text-3x py-6 xl:py-12',
      secondary: 'text-xl leading-32 sm:text-2xl xl:text-2x sm:leading-10 py-6 xl:py-12 max-w-3/5',
      tertiary: 'max-w-3/4 py-6 xl:py-12',
      service: 'h3 max-w-[40%] self-center [&>*]:inline py-14',
    },
  },
});

const CtaStyle = cva('absolute bottom-12', {
  variants: {
    position: {
      right: 'right-12',
      left: 'left-12',
    },
  },
  defaultVariants: {
    position: 'right',
  },
});

/**
 * @REMARK: What is the use of this wrapper opposed to the actual wrapper?
 */
const IconComponent = ({ icon }: { icon: IconDefinition }) => (
  <div className="text-2x absolute right-6 top-6 text-white">
    <FontAwesomeIcon icon={icon} />
  </div>
);

const Primary = ({ image, variant, cornerVariant, icon, text, link, alignment = 'right' }: PrimaryProps) => (
  <>
    {image && (
      <Image
        {...image}
        className={twMerge(CornerStyle({ variant: cornerVariant }), 'h-full object-cover')}
        height={500}
        image={image.image}
        resizingBehavior="fill"
        sizes="(max-width: 400px) 100vw, (min-width: 768px) 40vw, 100vw"
        width={750}
        wrapperClassName={'absolute inset-x-0 inset-y-0 w-auto h-auto'}
      />
    )}
    {!image && icon && <IconComponent icon={icon} />}
    <div className={TextStyle({ variant })}>{text}</div>
    <div className={CtaStyle({ position: alignment })}>
      <Button
        className="ml-3 bg-white"
        href={link.href}
        icon={faArrowRight}
        iconPosition="right"
        label={link.label}
        type="secondary"
      />
    </div>
  </>
);

const Secondary = ({ image, icon, text, variant }: SecondaryProps) => (
  <>
    {image && (
      <div
        className={
          'right-4.5 z-1 xl:rounded-br-50 absolute bottom-0 h-[55%] xl:right-0 xl:h-[110%] xl:w-auto xl:overflow-hidden'
        }
      >
        <Image
          {...image}
          className={'w-auto'}
          image={image.image}
          sizes="(max-width: 400px) 100vw, (min-width: 768px) 40vw, 100vw"
          width={500}
          wrapperClassName="w-auto"
        />
      </div>
    )}
    {!image && icon && <IconComponent icon={icon} />}
    <div className={TextStyle({ variant })}>{text}</div>
  </>
);

const Service = ({ image, icon, text, variant }: SecondaryProps) => (
  <>
    {image && (
      <div className={'z-1 max-[50%] absolute bottom-0 right-3 flex max-h-[115%] justify-end'}>
        <Image
          {...image}
          className="object-contain"
          focalpoint="right"
          image={image.image}
          width={500}
          wrapperClassName="h-auto"
        />
      </div>
    )}
    {!image && icon && <IconComponent icon={icon} />}
    <div className={TextStyle({ variant })}>
      {text}
      {icon && <FontAwesomeIcon icon={icon} />}
    </div>
  </>
);

const PromotionCta = ({
  className,
  alignment,
  icon,
  image,
  link,
  noRoundedCorners,
  text,
  theme,
  variant = 'primary',
}: Props) => {
  const backgroundColor = theme
    ? {
        pink: 'bg-pink',
        green: 'bg-green',
        blue: 'bg-blue',
        grey: 'bg-gray',
        beige: 'bg-beige',
      }[theme]
    : '';

  const cornerVariant = noRoundedCorners ? 'noCorners' : variant;

  if (variant === 'secondary') {
    const Wrapper = isAnchorLink(link?.href || '') ? 'a' : Link;

    return (
      <Wrapper
        className={twMerge(CornerStyle({ variant: cornerVariant }), className, backgroundColor)}
        href={link?.href}
      >
        <Secondary icon={icon} image={image} text={text} variant={variant} />
      </Wrapper>
    );
  }

  if (variant === 'service') {
    const Wrapper = isAnchorLink(link?.href || '') ? 'a' : Link;

    return (
      <Wrapper
        className={twMerge(CornerStyle({ variant: cornerVariant }), className, backgroundColor)}
        href={link?.href}
      >
        <Service icon={icon} image={image} text={text} variant={variant} />
      </Wrapper>
    );
  }

  return (
    <div className={twMerge(CornerStyle({ variant: cornerVariant }), className, backgroundColor)}>
      <Primary
        alignment={alignment}
        cornerVariant={cornerVariant}
        icon={icon}
        image={image}
        link={link}
        text={text}
        variant={variant}
      />
    </div>
  );
};

export default PromotionCta;
