import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import { trackEvents } from '@boss/constants/shared';
import { RichText } from '@boss/rich-text';
import { InspirationFields, InspirationImageFields, SimpleColor } from '@boss/types/b2b-b2c';
import { ColoraButton as Button, InspirationImage, Section, icons } from '@boss/ui';
import { getEntries, getInspirationTranslatedSlug } from '@boss/utils';

import { useProductsById } from '../client-queries';
import { CarouselProductsWrapper, ColorGridWrapper, CtaCardsSection, ProductCardWrapper } from '../components';
import { useProductMap } from '../hooks';
import { inspirationGridVariant, isB2b } from '../utils';

type Props = {
  inspiration: InspirationFields;
  locale: string;
  relatedImages: InspirationImageFields[];
  usedColors: SimpleColor[];
};

type ButtonProps = {
  label: string;
  filterName: string;
  filterValue: string;
  className: string;
  locale: string;
};

const FilterButtonStyles = cva('mx-2 my-2', {
  variants: {
    variant: {
      primary: '',
      secondary: 'rounded-none',
    },
  },
});

const FilterButton = ({ label, filterName, filterValue, className, locale }: ButtonProps) => {
  const translatedSlug = getInspirationTranslatedSlug(locale);
  const query = new URLSearchParams({ filt: filterName, val: filterValue }).toString();

  return (
    <Button
      className={twMerge(FilterButtonStyles({ variant: inspirationGridVariant }), className)}
      href={`${translatedSlug}?${query}`}
      label={label}
      testId={'button-with-href-to-' + translatedSlug}
      type="primary"
    />
  );
};

const InspirationDetailPage = ({ relatedImages, inspiration, locale, usedColors }: Props) => {
  const productIds = inspiration?.usedProducts?.map(product => product.productID) || [];
  const { mapProductComponent } = useProductMap();
  const { data: relatedProducts } = useProductsById(productIds);
  const { t } = useTranslation(['common', 'product', 'inspiration']);
  const straightCornerPosition = 3;

  const { title, description, image, look, style, area, tint } = inspiration;
  const filters = { look, style, area, tint };
  const hasFilters = look?.length || style?.length || area?.length || tint?.length;

  return (
    <div className="w-full">
      <h1 className="mb-5 md:mb-7">{t('inspirationTitle', { ns: 'inspiration' })}</h1>
      <div className="lg:gap-30 grid grid-cols-1 gap-10 md:grid-cols-5">
        <InspirationImage
          asset={image.image}
          className="rounded-35 mb-0 shadow-lg md:col-span-3"
          imageClassName="h-full"
          order={3}
          sizes="(max-width: 400px) 100vw, (min-width: 768px) 40vw, 100vw"
          width={1000}
        />
        <div className="flex flex-col md:col-span-2 md:justify-center">
          <h3 className="mb-5">
            <RichText
              content={title}
              mapperOptions={{ locale }}
              renderOptions={{
                noParagraph: true,
                noWrapper: true,
              }}
            />
          </h3>
          {description && <RichText content={description} mapperOptions={{ locale }} />}
        </div>
      </div>

      {!!usedColors.length && (
        <Section
          content={
            <div className="flex flex-col gap-5">
              <ColorGridWrapper
                className="md:w-full md:max-w-full"
                colors={usedColors}
                gridClassName="md:gap-5 lg:gap-10 md:grid-cols-6 lg:grid-cols-8"
                onClickAction="navigate"
                onFavorite={() => '/'} // TODO: Implement onFavorite post wishlist
                title={t('usedColors.title', { ns: 'inspiration' })}
                titleClassName="h4"
                translations={t('colorPickerModal', { ns: 'product', returnObjects: true })}
                variant="secondary"
              ></ColorGridWrapper>
              {!isB2b && (
                <div className="relative flex flex-row items-center gap-4 md:mt-0 md:pl-0">
                  <FontAwesomeIcon
                    className="text-red-dark rotate-[70deg] text-[5rem]"
                    icon={icons.pointingArrowRight}
                  />
                  <p className="caption pt-10 italic md:mt-0">
                    {t('usedColors.disclaimer.1', { ns: 'inspiration' })}
                    <br />
                    {t('usedColors.disclaimer.2', { ns: 'inspiration' })}
                  </p>
                </div>
              )}
            </div>
          }
        />
      )}

      {!!relatedProducts?.length && (
        <Section
          content={
            <CarouselProductsWrapper
              products={relatedProducts}
              title={t('recommendedProducts.title', { ns: 'product' }) as string}
            >
              {relatedProducts.map((product, index) => {
                if (!product) {
                  return null;
                }

                const _trackInfo = {
                  customEvent: trackEvents.SELECT_ITEM,
                  index,
                  item_list_name: 'related products',
                  item_id: product.id,
                  item_name: product.name,
                };

                return <ProductCardWrapper {...mapProductComponent(product)} key={product.id} trackInfo={_trackInfo} />;
              })}
            </CarouselProductsWrapper>
          }
        />
      )}

      {hasFilters && (
        <div className="mb-10 flex flex-wrap items-baseline">
          <p>{t('inspirationMore', { ns: 'inspiration' })}</p>
          {getEntries(filters).flatMap(([filter, valueArr]) =>
            valueArr?.map(option => (
              <FilterButton
                className="hover:bg-brown-dark bg-beige-light text-brown-dark mx-2 my-2 hover:text-white"
                filterName={t(`filters.${filter}`, { ns: 'inspiration' })}
                filterValue={t(`filters.${option}`, { ns: 'inspiration' })}
                key={option}
                label={t(`filters.${option}`, { ns: 'inspiration' })}
                locale={locale}
              />
            )),
          )}
        </div>
      )}

      {!!inspiration.generalContent?.callToActions?.length && (
        <CtaCardsSection callToActions={inspiration.generalContent?.callToActions} className="pt-0 md:pt-0" />
      )}

      {!!relatedImages.length && (
        <>
          <h3 className="mb-9">{t('inspirationStyles', { ns: 'inspiration' })}</h3>
          <div className="width-full mb-25 md:mb-50 flex flex-wrap gap-6 md:flex-nowrap">
            {relatedImages.map(({ slug, image, id }, i) => (
              <InspirationImage
                asset={image.image}
                className="rounded-35 block w-full shadow-lg"
                imageClassName="h-85"
                key={id}
                order={i + straightCornerPosition}
                sizes="(max-width: 400px) 100vw, (min-width: 768px) 40vw, 100vw"
                slug={slug}
                width={1000}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default InspirationDetailPage;
