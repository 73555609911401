import * as bossClient from '@boss/boss-client';
import { IOrder } from '@boss/types/b2b-b2c';

import { mapOrder } from './mapper';
import { PAYMENT_TYPE, PAYMENT_TYPE_UNION } from '../client';

export interface OrderParams {
  basketId: string;
  paymentType: PAYMENT_TYPE_UNION;
  payReference: string | undefined;
  timeStamp: string;
  amount?: number;
}

export const getHistoricOrders = async (): Promise<IOrder[] | undefined> => await bossClient.order.getHistoricOrders();
export const getOrdersByDate = async (): Promise<IOrder[] | undefined> => await bossClient.order.getOrdersByDate();
export const getOrderById = async (orderId: string, locale: string): Promise<IOrder | null> => {
  const order = await bossClient.orderClient.getOrderById(orderId, locale);

  return order ? mapOrder(order) : null;
};

export const createOrder = async ({
  basketId,
  paymentType,
  payReference,
  timeStamp,
  amount,
  m2m = false,
  locale,
}: {
  basketId: string;
  paymentType: PAYMENT_TYPE_UNION;
  payReference: string | undefined;
  timeStamp: string;
  amount?: number;
  m2m?: boolean;
  locale: string;
}): Promise<IOrder | null> => {
  let order;

  /*
   * PAYMENT_TYPE.cashWorkshop (3) is mapped to PAYMENT_TYPE.cash (1) since the payment type is not supported by the order API
   */
  const mappedPaymentType = paymentType === PAYMENT_TYPE.cashWorkshop ? PAYMENT_TYPE.cash : paymentType;

  const params = {
    basketid: basketId,
    paymenttype: mappedPaymentType.toString(),
    payreference: payReference ?? '',
    paytimestamp: timeStamp,
    amount: amount ? amount?.toString() : '0',
  };

  if (m2m) {
    order = await bossClient.order.createOrder(params, locale);
  } else {
    order = await bossClient.orderClient.createOrder(params, locale);
  }

  return order ? mapOrder(order) : null;
};
