import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { IAccountPriceInfBlock, IArticle } from '@boss/types/b2b-b2c';
import { Counter, PackagingInfo, Presence, Price, Table } from '@boss/ui';

import type { Props as CommonProps } from '../';
import { useAccountPriceInfoBySkuIds } from '../../../client-queries';
import { usePrice } from '../../../hooks';
import { getPackagingOptions, unitConfig } from '../../../utils';
import Packaging from '../Packaging';
import ArticlesTableSkeleton from '../Skeleton';

export type ArticleValues = {
  quantity: number;
  unit: string;
  productImageSrc?: string;
  lineId?: string;
  price?: number;
  discount?: number;
};
export type ArticleIdsObject = Record<string, ArticleValues>;

const BASE_HEADER_KEYS = ['packaging', 'amount', 'price'] as const;
const NON_PAINT_HEADER_KEYS = [...BASE_HEADER_KEYS, 'variant'] as const;
const PAINT_HEADER_KEYS = [...BASE_HEADER_KEYS, 'content'] as const;
const ALL_HEADER_KEYS = [...new Set([...NON_PAINT_HEADER_KEYS, ...PAINT_HEADER_KEYS])] as const;

type NonPaintHeaderKey = (typeof NON_PAINT_HEADER_KEYS)[number];
type PaintHeaderKey = (typeof PAINT_HEADER_KEYS)[number];

type Props = CommonProps & {
  updateSelectedArticlesIdsObject: (id: string, newObject: ArticleValues) => void;
};

const createTableDataItems = ({
  articles,
  selectedArticleIdsObject,
  updateSelectedArticlesIdsObject,
  showPrivatePrices,
  accountPriceInfo,
  translate,
}: {
  articles?: IArticle[];
  selectedArticleIdsObject: ArticleIdsObject;
  accountPriceInfo: IAccountPriceInfBlock[] | null | undefined;
  updateSelectedArticlesIdsObject: (id: string, newObject: ArticleValues) => void;
  showPrivatePrices: boolean;
  translate: (key: string, options?: Record<string, string | number>) => string;
}) => {
  if (!articles?.length || !getPackagingOptions) {
    return null;
  }

  return articles.map(article => {
    const { id: articleId, unit: defaultUnit, description } = article;
    const { unit = defaultUnit, quantity = 0 } = selectedArticleIdsObject[articleId] || {};
    const packagingOptions = getPackagingOptions(article);

    const priceInfo = accountPriceInfo?.find(
      (priceInfo: IAccountPriceInfBlock) => priceInfo?.price?.id === articleId,
    )?.price;

    const price = priceInfo?.linediscount ? priceInfo?.netunitprice : article.pricing.price;
    const strikePrice = priceInfo?.linediscount ? priceInfo?.grossunitprice : undefined;
    const privatePrice = article.pricing?.privatePrice ?? 0;
    const consumptionPerLayer = article.packaging?.indicativeLayerM2;

    return {
      variant: description, // This line is specific to non-paint data
      content: (
        <div>
          <PackagingInfo packageValue={description} />
          {consumptionPerLayer && (
            <div className="m-0 p-0 text-left text-xs lowercase text-gray-500">
              {translate('consumptionPerLayer', { value: consumptionPerLayer })}
            </div>
          )}
        </div>
      ), // This line is specific to paint data
      packaging: (
        <Packaging
          onChange={e => updateSelectedArticlesIdsObject(articleId, { quantity, unit: e.target.value, price })}
          packagingOptions={packagingOptions}
          unit={unit}
        />
      ),
      amount: (
        <div className="flex flex-col items-center">
          <Counter
            onChange={quantity => updateSelectedArticlesIdsObject(articleId, { quantity, unit, price })}
            value={quantity}
          />
        </div>
      ),

      price: (
        <div className="-pt-5 flex flex-col items-center">
          <Price
            className="text-right [&>*]:font-sans"
            privatePrice={showPrivatePrices ? privatePrice : undefined}
            size="small"
            strikePrice={strikePrice}
            value={price}
            variant={strikePrice ? 'strike' : 'info'}
          />
        </div>
      ),
    };
  });
};

const PrimaryArticlesTable = ({
  articles,
  articleType,
  selectedArticleIdsObject,
  updateSelectedArticlesIdsObject,
  showPrivatePrices,
  type = 'default',
  isLoading,
}: Props) => {
  const { visible: showUnits } = unitConfig;
  const { showPrice } = usePrice();
  const isPaint = articleType === 'paint';
  const { t } = useTranslation(['common', 'product']);

  const skuIds = articles?.map(article => article.id) ?? [];
  const { data: accountPriceInfo, isLoading: isLoadingAccountPriceInfo } = useAccountPriceInfoBySkuIds(skuIds);

  const getHeaderFilterKeys = () => {
    if (type == 'slim') {
      return ALL_HEADER_KEYS.filter(key => key !== 'content');
    }

    const headerFilterKeys: (NonPaintHeaderKey | PaintHeaderKey)[] = [];

    if (!showPrice) {
      headerFilterKeys.push('price');
    }

    if (!showUnits) {
      headerFilterKeys.push('packaging');
    }

    return headerFilterKeys;
  };

  const tableData = useMemo(() => {
    const headers =
      articleType === 'paint'
        ? ['content', 'packaging', 'amount', 'price']
        : ['variant', 'packaging', 'amount', 'price'];

    const items = createTableDataItems({
      articles,
      accountPriceInfo,
      selectedArticleIdsObject,
      updateSelectedArticlesIdsObject,
      showPrivatePrices: !!showPrivatePrices,
      translate: t,
    });

    return {
      headers: headers.map(key => ({ key: key as NonPaintHeaderKey | PaintHeaderKey, label: t(key) })),
      items,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articles, selectedArticleIdsObject, accountPriceInfo, showPrivatePrices, articleType, t]);
  const headerFilterKeys = getHeaderFilterKeys();
  const headers = tableData.headers.filter(header => !headerFilterKeys.includes(header.key));

  return (
    <Presence
      id="articles-table-presence"
      isLoading={isLoading || isLoadingAccountPriceInfo}
      loader={<ArticlesTableSkeleton />}
      visible={!!articles?.length}
    >
      {!!tableData.items?.length && (
        <div className="flex flex-col gap-2">
          <Table
            headers={headers}
            items={tableData.items}
            tdClassName="px-1"
            thClassName={twMerge(
              'border-b-[1rem] border-transparent',
              isPaint ? 'first:text-left' : 'first:text-center',
            )}
            trClassName={twMerge('border-b-[1rem] border-transparent ', type === 'slim' ? 'inline-block mr-4' : '')}
          />
        </div>
      )}
    </Presence>
  );
};

export default PrimaryArticlesTable;
