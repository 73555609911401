import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'next-i18next';

import { IEvent } from '@boss/services';
import { Button, EventCard, ShoppingCartTotalPrice } from '@boss/ui';
import { formatDate, formatPrice, formatTimeRange, getLocalizedSlugForEventPage, slugify } from '@boss/utils';

import { eventConfig, isB2b } from '../../utils';

const EventCardWrapper = ({ event, locale }: { event: IEvent; locale: string }) => {
  const eventLink = getLocalizedSlugForEventPage(locale, slugify(event.id));
  const { variant } = eventConfig;
  const { t } = useTranslation('event');

  const timeRange = formatTimeRange(event?.startTime, event?.endTime, locale as string);

  return (
    <EventCard
      footerAction={
        !event.complete ? (
          <Button
            className="py-3"
            href={eventLink}
            icon={faArrowRight}
            iconPosition="right"
            label={t('sections.overview.actions.register')}
            type="primary"
          />
        ) : (
          <Button
            className="bg-red-dark py-3"
            href={eventLink}
            label={t('sections.overview.actions.full')}
            type="primary"
          />
        )
      }
      footerContent={
        !isB2b ? (
          <ShoppingCartTotalPrice
            className="pt-0"
            price={formatPrice(event.price, locale)}
            totalText={t('price', { ns: 'workshop' })}
          />
        ) : null
      }
      href={eventLink}
      image={event.image}
      key={event.id}
      title={event.title}
      variant={variant}
    >
      <div className="text-gray-dark py-3">
        {event.date && (
          <div className="flex justify-between">
            <span className="capitalize">
              {new Date(event.date).toLocaleDateString(locale, { weekday: 'short' })}. {formatDate(event.date)}
            </span>
            <span>{timeRange}</span>
          </div>
        )}
        {event.location && <p>{event.location}</p>}
      </div>
    </EventCard>
  );
};

export default EventCardWrapper;
