import { faMailbox } from '@fortawesome/pro-regular-svg-icons';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { ButtonType } from '@boss/types/corporate-jobs';
import { Button, Modal } from '@boss/ui';

import { useSubmitForm } from '../../client-queries';
import { getBaseUrl } from '../../constants';
import { useFormField } from '../../hooks';
import { buildFormFields, getPaintGuideResultUrl } from '../../utils';
import DynamicForm from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

type Props = { className?: string; type?: ButtonType };

const MailPaintguideButton = ({ className, type = 'secondary' }: Props) => {
  const { t } = useTranslation(['paintguide', 'common', 'product', 'forms']);
  const [showMailResultModal, setShowMailResultModal] = useState(false);
  const { firstname, lastname, email } = useFormField();
  const fields: FormField[] = [firstname, lastname, email];
  const { locale, asPath } = useRouter();
  const host = getBaseUrl(locale ?? '');
  const resultpageUrl = `${host}${asPath}`;
  const successToast = {
    title: t('toast.mailPaintguide.success.title', { ns: 'common' }),
    description: t('toast.mailPaintguide.success.description', { ns: 'common' }),
  };
  const errorToast = {
    title: t('toast.mailPaintguide.error.title', { ns: 'common' }),
    description: t('toast.mailPaintguide.error.description', { ns: 'common' }),
  };

  const {
    mutate: submitForm,
    isLoading: isSubmitting,
    reset,
  } = useSubmitForm({
    toasts: { success: successToast, error: errorToast },
  });

  return (
    <>
      <Button
        className={twMerge(className, 'ml-auto px-6 py-4')}
        icon={faMailbox}
        iconPosition="left"
        label={t('mailResults')}
        onClick={() => setShowMailResultModal(!showMailResultModal)}
        type={type}
      />
      {showMailResultModal && (
        <Modal className="p-15 md:p-20" onClose={() => setShowMailResultModal(false)}>
          <DynamicForm
            fields={buildFormFields(fields)}
            id="PaintGuide"
            isSubmitting={isSubmitting}
            onCloseAlert={reset}
            onSubmit={values =>
              submitForm({
                ...values,
                type: 'PaintguideResult',
                paintguideresultsurl: getPaintGuideResultUrl(resultpageUrl),
              })
            }
            variant="light"
          />
        </Modal>
      )}
    </>
  );
};

export default MailPaintguideButton;
