import { eventPageSlugsB2B, eventPageSlugsB2C } from '@boss/constants/b2b-b2c';
import { CtaFields } from '@boss/types/b2b-b2c';
import { getPagePathByEntry } from '@boss/utils';

import { isB2b } from '..';

export const getCtaHref = (entry: CtaFields, locale: string) => {
  const { internalLink, externalLink, anchor, filteredEventOverviewLink, internalFixedLink } = entry;

  if (filteredEventOverviewLink) {
    const filters = {
      type: filteredEventOverviewLink.type,
      locationId: filteredEventOverviewLink.locationid,
    };

    const queryString = Object.entries(filters)
      .filter(([_, value]) => value)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    return `${isB2b ? eventPageSlugsB2B[locale] : eventPageSlugsB2C[locale]}?${queryString}`;
  }

  if (internalFixedLink) {
    return internalFixedLink;
  }

  if (internalLink) {
    const internalLinkPath = getPagePathByEntry(internalLink, locale);
    const anchorLink = anchor?.anchorLinkText ? `#${anchor.anchorLinkText}` : '';

    return `${internalLinkPath}${anchorLink}`;
  } else {
    return externalLink ?? '';
  }
};
