import * as Sentry from '@sentry/nextjs';

import { CHANNEL } from '@boss/constants/b2b-b2c';
import { article as articleService } from '@boss/services/client';

import { COLOR_TYPE } from '../../constants/product';

const fetchArticlesByIds = async (locale: string, ids: string[], colorCode?: string) => {
  try {
    return await articleService.byIds({ ids, locale, channel: CHANNEL, colorCode }); // Exclude the possible [null] value when the given ids arn't found
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'Fetch article by id',
      },
    });
  }
};

const fetchArticlesByProductAndColor = async ({
  colorId,
  productId,
  colorCode,
  colorType,
  locale,
  isPaint,
}: {
  colorId: string;
  colorCode: string;
  productId: string;
  colorType: string;
  locale: string;
  isPaint: boolean;
}) => {
  try {
    if (!isPaint) {
      return await articleService.byProductId({ productId, locale, channel: CHANNEL });
    }

    if (colorType === COLOR_TYPE.MYMX) {
      return await articleService.getMymxArticles({ colorId, productId, locale, channel: CHANNEL });
    } else if (colorType === COLOR_TYPE.PREMIXED && colorCode) {
      return await articleService.getPremixedArticles({ colorId: colorCode, productId, locale, channel: CHANNEL });
    } else if (colorType === COLOR_TYPE.BASE && colorCode) {
      return await articleService.getBaseArticles({ colorId: colorCode, productId, locale, channel: CHANNEL });
    }

    return await articleService.byProductId({ productId, locale, channel: CHANNEL });
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'Fetch article by product and color',
      },
    });
  }
};

const fetchAccountPriceInfoBySkuIds = async (skuIds: string[], locale: string, isLoggedIn: boolean) => {
  try {
    return await articleService.getAccountPriceInfoBySkuIds({ skuIds, locale, isLoggedIn });
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'Fetch account price info by sku ids',
      },
    });
  }
};

const fetchAccountPriceInfoBySkuId = async ({
  skuId,
  locale,
  unit,
  quantity,
}: {
  skuId: string;
  locale: string;
  unit: string;
  quantity: number;
}) => {
  try {
    return await articleService.getAccountPriceInfoBySkuId({ skuId, locale, unit, quantity });
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'Fetch account price info by sku id',
      },
    });
  }
};

const fetchArticleDiscountInfo = async ({
  locale,
  unit,
  quantity,
  skuId,
}: {
  locale: string;
  unit: string;
  quantity: number;
  skuId: string;
}) => {
  try {
    return await articleService.getDiscountInfoBySkuId({ locale, unit, quantity, skuId });
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'Fetch article discount info',
      },
    });
  }
};

export {
  fetchArticlesByIds,
  fetchArticlesByProductAndColor,
  fetchAccountPriceInfoBySkuIds,
  fetchAccountPriceInfoBySkuId,
  fetchArticleDiscountInfo,
};
