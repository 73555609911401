import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { ReactElement, ReactNode } from 'react';

import ProductCardPrimary from './Primary';
import ProductCardSecondary from './Secondary';
import { CardLabelProps } from '../CardLabel';

type Translations = {
  quantityPrefix: string;
  disclaimer: string;
  unitPrice?: string;
  unitLabel?: string;
};

// TODO:
//  1. Use interface
//  2. Move to internal types folder to prevent exporting
//     as a public type. Should be private.
export type ProductCardProps = {
  imageSrc?: string | null;
  labels?: CardLabelProps[];
  price?: number;
  slug?: string;
  specifications?: ReactNode[];
  title: string;
  id: string;
  cartLabel?: string;
  addLabel?: string;
  className?: string;
  handleAddToCart?: (count: number) => void;
  handleAddToWishlist?: () => void;
  inWishlist?: boolean;
  variant?: 'primary' | 'secondary';
  renderRating?: () => ReactElement | null;
  description?: ReactNode;
  translations?: Translations;
  cartIcon?: IconDefinition;
  plusIcon?: IconDefinition;
  hideAddToCartAction?: boolean;
  handleSelect?: () => void;
  handleRemove?: () => void;
  testid?: string;
  priceIsLoading?: boolean;
  trackInfo?: Record<string, string | number>;
  strikePrice?: number;
  showCounter?: boolean;
  counterMin?: number;
};

const ProductCard = ({ variant = 'primary', ...props }: ProductCardProps) => {
  if (variant === 'primary') {
    return <ProductCardPrimary {...props} />;
  }
  if (variant === 'secondary') {
    return <ProductCardSecondary {...props} />;
  }
  return null;
};

export default ProductCard;
