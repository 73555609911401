import { faCalendarAlt, faSliders } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { useRouter } from '@boss/hooks';
import { Accordion, Button } from '@boss/ui';

import FromToDatePicker from '../Date/FromToDatePicker';

interface Props {
  fromDate?: string;
  toDate?: string;
  onSetFilters: (filters: { fromDate?: string; toDate?: string }) => void;
  unsetTodayAsMaxDate?: boolean;
  collapsable?: boolean;
}

const DateFilterOptions = ({ date, label }: { date: string; label: string }) => {
  const { locale } = useRouter();

  return (
    <div>
      <FontAwesomeIcon className="mr-2" icon={faCalendarAlt} />
      <span>{label}: </span>
      <span>{toLocalizedDate(date, locale)}</span>
    </div>
  );
};

const toLocalizedDate = (date: string, locale: string) => {
  return new Date(date).toLocaleDateString(locale, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

const DateFilters = ({
  collapsable = true,
  fromDate,
  toDate,
  onSetFilters: handleSetFilters,
  unsetTodayAsMaxDate,
}: Props) => {
  const { t } = useTranslation('account');
  const [selectedFilters, setSelectedFilters] = useState<{ fromDate?: string; toDate?: string }>({ fromDate, toDate });

  const applySelectedFilters = (filters: { fromDate?: string; toDate?: string }) => {
    setSelectedFilters(filters);
    handleSetFilters(filters);
  };

  const changes = JSON.stringify(selectedFilters) !== JSON.stringify({ fromDate, toDate });

  const datePickerContent = (
    <FromToDatePicker
      className="flex bg-gray-50 p-4"
      filters={{
        fromDate: selectedFilters.fromDate ? new Date(selectedFilters.fromDate) : undefined,
        toDate: selectedFilters.toDate ? new Date(selectedFilters.toDate) : undefined,
      }}
      setFilters={filters =>
        setSelectedFilters({
          fromDate: filters.fromDate ? filters.fromDate.toDateString() : '',
          toDate: filters.toDate ? filters.toDate.toDateString() : '',
        })
      }
      unsetTodayAsMaxDate={unsetTodayAsMaxDate}
    />
  );

  return (
    <>
      {collapsable ? (
        <Accordion
          buttonClassName="py-4 text-blue-dark font-bold justify-end gap-4 font-normal"
          closedIcon={faSliders}
          title={t('invoices.filters.title')}
          variant="simple"
        >
          {datePickerContent}
        </Accordion>
      ) : (
        datePickerContent
      )}
      {(selectedFilters.fromDate ?? selectedFilters.toDate) && (
        <section className="flex flex-col gap-4 rounded border p-4 font-bold shadow-sm md:flex-row">
          <div>{t('invoices.filters.appliedFilters')}:</div>
          {selectedFilters.fromDate && (
            <DateFilterOptions date={selectedFilters.fromDate} label={t('invoices.filters.from')} />
          )}
          {selectedFilters.toDate && (
            <DateFilterOptions date={selectedFilters.toDate} label={t('invoices.filters.to')} />
          )}
          <div className="flex gap-5 md:ml-auto">
            {changes && (
              <Button
                label={t('invoices.filters.apply')}
                onClick={() => applySelectedFilters(selectedFilters)}
                type="secondary"
              />
            )}
            <button
              className="flex items-center gap-2 text-[#132D88]"
              onClick={() => {
                applySelectedFilters({});
              }}
            >
              {t('invoices.filters.clear')}
            </button>
          </div>
        </section>
      )}
    </>
  );
};

export default DateFilters;
