import type { LocalizedSlugs } from '@boss/types/b2b-b2c';

export const productDetailPageBase = 'p';

export const notFoundSlugs: LocalizedSlugs = {
  fr: '404',
  nl: '404',
  'nl-NL': '404',
};

export const homePageSlugs: LocalizedSlugs = {
  fr: '/',
  nl: '/',
  'nl-NL': '/',
};

export const stepByStepPageSlugs: LocalizedSlugs = {
  fr: 'feuille-de-route',
  nl: 'stappenplannen',
  'nl-NL': 'stappenplannen',
};

export const searchPageSlugs: LocalizedSlugs = {
  fr: '/chercher',
  nl: '/zoeken',
  'nl-NL': '/zoeken',
};

export const inspirationPageSlugs: LocalizedSlugs = {
  fr: '/images-inspirantes',
  nl: '/inspiratiebeelden',
  'nl-NL': '/inspiratiebeelden',
};

export const categoryPageSlugs: LocalizedSlugs = {
  fr: '/c',
  nl: '/c',
  'nl-NL': '/c',
};

export const actionPageSlugs: LocalizedSlugs = {
  fr: `${categoryPageSlugs['fr']}/action`,
  nl: `${categoryPageSlugs['nl']}/actie`,
  'nl-NL': `${categoryPageSlugs['nl-NL']}/actie`,
};

export const categoryPaintPageSlugs: LocalizedSlugs = {
  fr: `${categoryPageSlugs['fr']}/peintures`,
  nl: `${categoryPageSlugs['nl']}/verf`,
  'nl-NL': `${categoryPageSlugs['nl-NL']}/verf`,
};

export const storePageSlugs: LocalizedSlugs = {
  fr: 'magasins',
  nl: 'verkooppunten',
  'nl-NL': 'verkooppunten',
};

export const eventPageSlugsB2C: LocalizedSlugs = {
  fr: 'ateliers',
  nl: 'workshops',
  'nl-NL': 'workshops',
};

export const eventPageSlugsB2B: LocalizedSlugs = {
  fr: 'formations',
  nl: 'opleidingen',
  'nl-NL': 'opleidingen',
};

export const colorPageSlugs: LocalizedSlugs = {
  fr: 'couleurs',
  nl: 'kleuren',
  'nl-NL': 'kleuren',
};

export const otherStorePageSlugs: LocalizedSlugs = {
  fr: 'magasins/magasins',
  nl: 'verkooppunten/verkooppunten',
  'nl-NL': 'verkooppunten/verkooppunten',
};

export const paintguidePageSlugs: LocalizedSlugs = {
  fr: 'guide-peintures',
  nl: 'verfwijzer',
  'nl-NL': 'verfwijzer',
};

export const colorToolSlugs: LocalizedSlugs = {
  fr: 'outil-de-coloration',
  nl: 'kleurtool',
  'nl-NL': 'kleurtool',
};

export const basketPageSlugs: LocalizedSlugs = {
  fr: 'panier',
  nl: 'winkelmandje',
  'nl-NL': 'winkelmandje',
};

export const checkoutPageSlugs: LocalizedSlugs = {
  fr: 'checkout',
  nl: 'checkout',
  'nl-NL': 'checkout',
};

const WORKSHOP_CHECKOUT_PATH = 'checkout/workshop' as const;

export const workshopCheckoutPageSlugs: LocalizedSlugs = {
  fr: WORKSHOP_CHECKOUT_PATH,
  nl: WORKSHOP_CHECKOUT_PATH,
  'nl-NL': WORKSHOP_CHECKOUT_PATH,
};

export const checkoutConfirmationSlugs: LocalizedSlugs = {
  fr: `${checkoutPageSlugs['fr']}/confirmation`,
  nl: `${checkoutPageSlugs['nl']}/bevestiging`,
  'nl-NL': `${checkoutPageSlugs['nl-NL']}/bevestiging`,
};

export const homeDeliverySlugsB2C: LocalizedSlugs = {
  fr: 'livraison-a-domicile',
  nl: 'levering-aan-huis',
  'nl-NL': 'levering-aan-huis',
};

export const homeDeliverySlugsB2B: LocalizedSlugs = {
  fr: 'livraisons-enlevements-retours',
  nl: 'leveren-afhalen-ophalen',
  'nl-NL': 'leveren-afhalen-ophalen',
};

export const termsAndConditionsPageSlugs: LocalizedSlugs = {
  fr: 'conditions-generales',
  nl: 'algemene-verkoopsvoorwaarden',
  'nl-NL': 'algemene-verkoopsvoorwaarden',
};

export const workshopPageSlugs: LocalizedSlugs = {
  fr: 'workshop',
  nl: 'workshop',
  'nl-NL': 'workshop',
};

export const loginPageSlugs: LocalizedSlugs = {
  fr: 'login',
  nl: 'login',
  'nl-NL': 'login',
};

export const registerPageSlugs: LocalizedSlugs = {
  fr: 'enregistrer',
  nl: 'registreren',
  'nl-NL': 'registreren',
};

export const insideViewerPageSlugs: LocalizedSlugs = {
  fr: 'realisations-inspirantes',
  nl: 'woonverhaal',
  'nl-NL': 'woonverhaal',
};

export const TESTIMONIALPageSlugs: LocalizedSlugs = {
  fr: 'temoignages',
  nl: 'getuigenissen',
  'nl-NL': 'getuigenissen',
};

export const professionalTipsPageSlugs: LocalizedSlugs = {
  fr: 'conseils-professionnels',
  nl: 'professionele-vaktips',
  'nl-NL': 'professionele-vaktips',
};

export const paintingTipsPageSlugs: LocalizedSlugs = {
  fr: 'conseil-peinture',
  nl: 'verfadvies',
  'nl-NL': 'verfadvies',
};

export const colorTipsPageSlugs: LocalizedSlugs = {
  fr: 'conseils-couleurs',
  nl: 'kleuradvies',
  'nl-NL': 'kleuradvies',
};

export const spacePageSlugs: LocalizedSlugs = {
  fr: 'page-espaces',
  nl: 'ruimtepaginas',
  'nl-NL': 'ruimtepaginas',
};

export const accountPageSlugs: LocalizedSlugs = {
  fr: 'account',
  nl: 'account',
  'nl-NL': 'account',
};

export const jobsPageSlugs: LocalizedSlugs = {
  fr: 'offres-emplois',
  nl: 'vacatures',
  'nl-NL': 'vacatures',
};

export const accountDashboardSlugs: LocalizedSlugs = {
  fr: `${accountPageSlugs['fr']}/dashboard`,
  nl: `${accountPageSlugs['nl']}/dashboard`,
  'nl-NL': `${accountPageSlugs['nl-NL']}/dashboard`,
};

export const accountOrderSlugs: LocalizedSlugs = {
  fr: `${accountPageSlugs['fr']}/ordres`,
  nl: `${accountPageSlugs['nl']}/bestellingen`,
  'nl-NL': `${accountPageSlugs['nl-NL']}/bestellingen`,
};

export const accountInfoSlugs: LocalizedSlugs = {
  fr: `${accountPageSlugs['fr']}/info`,
  nl: `${accountPageSlugs['nl']}/info`,
  'nl-NL': `${accountPageSlugs['nl-NL']}/info`,
};

export const accountContactSlugs: LocalizedSlugs = {
  fr: `${accountPageSlugs['fr']}/contact`,
  nl: `${accountPageSlugs['nl']}/contact`,
  'nl-NL': `${accountPageSlugs['nl-NL']}/contact`,
};

export const accountProjectsSlugs: LocalizedSlugs = {
  fr: `${accountPageSlugs['fr']}/favoris`,
  nl: `${accountPageSlugs['nl']}/favorieten`,
  'nl-NL': `${accountPageSlugs['nl-NL']}/favorieten`,
};

export const accountColorsSlugs: LocalizedSlugs = {
  fr: `${accountPageSlugs['fr']}/couleurs`,
  nl: `${accountPageSlugs['nl']}/kleuren`,
  'nl-NL': `${accountPageSlugs['nl-NL']}/kleuren`,
};

export const accountPlansSlugs: LocalizedSlugs = {
  fr: `${accountPageSlugs['fr']}/plans`,
  nl: `${accountPageSlugs['nl']}/plannen`,
  'nl-NL': `${accountPageSlugs['nl-NL']}/plannen`,
};

export const accountAdviceSlugs: LocalizedSlugs = {
  fr: `${accountPageSlugs['fr']}/conseils`,
  nl: `${accountPageSlugs['nl']}/advies`,
  'nl-NL': `${accountPageSlugs['nl-NL']}/advies`,
};

export const accountAppointmentsSLugs: LocalizedSlugs = {
  fr: `${accountPageSlugs['fr']}/rendez-vous`,
  nl: `${accountPageSlugs['nl']}/afspraken`,
  'nl-NL': `${accountPageSlugs['nl-NL']}/afspraken`,
};

export const accountInvoicesSlugs: LocalizedSlugs = {
  fr: `${accountPageSlugs['fr']}/factures`,
  nl: `${accountPageSlugs['nl']}/facturen`,
  'nl-NL': `${accountPageSlugs['nl-NL']}/facturen`,
};

export const accountPaintguideSlugs: LocalizedSlugs = {
  fr: `${accountPageSlugs['fr']}/guide-peintures`,
  nl: `${accountPageSlugs['nl']}/verfwijzers`,
  'nl-NL': `${accountPageSlugs['nl-NL']}/verfwijzers`,
};

export const accountQuotationSlugs: LocalizedSlugs = {
  fr: `${accountPageSlugs['fr']}/offres`,
  nl: `${accountPageSlugs['nl']}/offertes`,
  'nl-NL': `${accountPageSlugs['nl-NL']}/offertes`,
};

export const accountListSlugs: LocalizedSlugs = {
  fr: `${accountPageSlugs['fr']}/listes`,
  nl: `${accountPageSlugs['nl']}/lijstjes`,
  'nl-NL': `${accountPageSlugs['nl-NL']}/lijstjes`,
};

export const accountDiscountSlugs: LocalizedSlugs = {
  fr: `${accountPageSlugs['fr']}/reductions`,
  nl: `${accountPageSlugs['nl']}/kortingen`,
  'nl-NL': `${accountPageSlugs['nl-NL']}/kortingen`,
};

export const accountPriceListPagesSlugs: LocalizedSlugs = {
  fr: '/listes-de-prix-pour-le-professionel',
  nl: '/prijslijsten-voor-vakman',
  'nl-NL': '/prijslijsten-voor-vakman',
};

export const colorCardProductSlugs: LocalizedSlugs = {
  fr: '/p/carte-de-couleur/30341',
  nl: '/p/kleurkaartje/30341',
  'nl-NL': '/p/kleurkaartje/30341',
};

export const colorTestProductSlugs: LocalizedSlugs = {
  fr: '/p/essai-couleur/1197',
  nl: '/p/kleurtester/1197',
  'nl-NL': '/p/kleurtester/1197',
};

export const accountMagazineSubscriptionPageSlugs: LocalizedSlugs = {
  fr: '/magazine',
  nl: '/magazine',
  'nl-NL': '/magazine',
};

export const reviewPolicyPageSlugs: LocalizedSlugs = {
  fr: '/avis-en-ligne',
  nl: '/online-review-policy',
  'nl-NL': '/online-review-policy',
};

export enum overviewTypes {
  INSIDE_VIEWER = 'pageTypeBinnenkijker',
  TESTIMONIAL = 'pageTypeGetuigenis',
  PROFESSIONAL_TIPS = 'pageTypeVaktips',
  PAINTING_TIPS = 'pageTypeVerftips',
  COLOR_TIPS = 'pageTypeKleurtips',
  SPACE_PAGES = 'pageTypeRuimtepaginas',
}

export const overviewPageSlugs = {
  [overviewTypes.INSIDE_VIEWER]: insideViewerPageSlugs,
  [overviewTypes.TESTIMONIAL]: TESTIMONIALPageSlugs,
  [overviewTypes.PROFESSIONAL_TIPS]: professionalTipsPageSlugs,
  [overviewTypes.PAINTING_TIPS]: paintingTipsPageSlugs,
  [overviewTypes.COLOR_TIPS]: colorTipsPageSlugs,
};

export const contenfulOverviewTypes = {
  [overviewTypes.INSIDE_VIEWER]: 'Cases overview' as const,
  [overviewTypes.TESTIMONIAL]: 'Testimonials overview' as const,
  [overviewTypes.PROFESSIONAL_TIPS]: 'Professional advice overview' as const,
  [overviewTypes.PAINTING_TIPS]: 'Paint advice overview' as const,
  [overviewTypes.COLOR_TIPS]: 'Color tips overview' as const,
};
