const Login = () => {
  return (
    <>
      <div className="heading">
        <h1 role="heading">Ben je bestaande klant?</h1>
      </div>
      <form aria-label="Aanmelden met uw e-mailadres" className="localAccount" id="localAccountForm">
        <div className="intro">
          <h2>Aanmelden met uw e-mailadres</h2>
        </div>
        <div aria-hidden="true" className="error pageLevel" role="alert">
          <p></p>
        </div>
        <div className="entry">
          <div className="entry-item">
            <label htmlFor="signInName">E-mailadres</label>
            <div aria-hidden="true" className="error itemLevel" role="alert" style={{ display: 'none' }}>
              <p>Voer uw E-mailadres in</p>
            </div>
            <input
              aria-label="E-mailadres"
              aria-required="true"
              autoFocus
              className=""
              id="signInName"
              name="E-mailadres"
              placeholder="E-mailadres"
              title="Voer een geldige e-mailadres in"
              type="text"
            />
            <div
              data-lastpass-icon-root=""
              style={{ position: 'relative', height: '0px', width: '0px', float: 'left' }}
            ></div>
          </div>
          <div className="entry-item">
            <div className="password-label">
              <label htmlFor="password">Wachtwoord</label>
              <a
                href="/biintb2cdta.onmicrosoft.com/B2C_1A_COLORA_TEST_SIGNIN/api/CombinedSigninAndSignup/unified?claimsexchange=ForgotPasswordExchange&amp;csrf_token=bFF0SFFMbXlOdVJCKzBUL2Nsay94c2RGVkFuYlMyVWcyU1kvMWpBWXJwWnJKV2hGa3paZFQrWEJURXVFOVBUclNDaVZGSnNJZTlyMTFpQm12VXYwVUE9PTsyMDI1LTAxLTIxVDA3OjMyOjIzLjYzOTgwNTVaO0JxVWVud1AxUGk0Ni80MzR1NDRWQnc9PTt7Ik9yY2hlc3RyYXRpb25TdGVwIjoxfQ==&amp;tx=StateProperties=eyJUSUQiOiI0ZTM0NjUyNi03MTU4LTQ2ZWEtYjY2NC0zYmNlNDU5NzkxNzkifQ&amp;p=B2C_1A_COLORA_TEST_SIGNIN"
                id="forgotPassword"
              >
                Ben je je wachtwoord vergeten?
              </a>
            </div>
            <div aria-hidden="true" className="error itemLevel" style={{ display: 'none' }}>
              <p role="alert">Voer uw wachtwoord in</p>
            </div>
            <input
              aria-label="Wachtwoord"
              aria-required="true"
              autoComplete="current-password"
              className=""
              id="password"
              name="Wachtwoord"
              placeholder="Wachtwoord"
              type="password"
            />
            <div
              data-lastpass-icon-root=""
              style={{ position: 'relative', height: '0px', width: '0px', float: 'left' }}
            ></div>
          </div>

          <div className="working"></div>

          <div className="buttons">
            <button form="localAccountForm" id="next" type="submit">
              Inloggen
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Login;
