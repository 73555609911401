import * as bossClient from '@boss/boss-client';
import { types as bossTypes } from '@boss/boss-client';

import { IAccountPriceInfo } from './types';

export const mapPriceInfo = (
  priceInfo: bossTypes.product.AccountPriceInfo['prices'] | undefined,
): IAccountPriceInfo['prices'] => ({
  fromPriceAmount: priceInfo?.frompriceamount ?? 0,
  fromPriceCurrency: priceInfo?.frompricecurrency ?? '',
  fromPriceUnit: priceInfo?.frompriceunit ?? '',
  fromPriceAmountNet: priceInfo?.frompriceamountnet ?? 0,
});

/**
 * Fetches account price info by product id
 */

export const getAccountPriceInfoByProductId = async ({
  productId,
  locale,
  isLoggedIn,
}: {
  productId: string;
  locale: string;
  isLoggedIn?: boolean;
}): Promise<IAccountPriceInfo> => {
  const accountPriceInfo = await bossClient.productClient.getAccountPriceInfoByProductId({
    productId,
    locale,
    isLoggedIn,
  });
  const priceInfo = accountPriceInfo?.prices;

  return {
    labels: accountPriceInfo?.labels ?? [],
    prices: mapPriceInfo(priceInfo),
  };
};
