import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { IAccountOrder } from '@boss/services/client';
import { Accordion, Alert, Presence, SearchBar } from '@boss/ui';

import { DateFilters, OrderOverviewSkeleton, PagedOrders } from '../../../components';
import { useOrders } from '../../../hooks';
import { isB2b } from '../../../utils';

interface OrderProps {
  title?: string;
  isOpen: boolean;
  orders: IAccountOrder[];
  totalItems: number;
  activePage: number;
  onPaginate: (pageNumber: number) => void;
  pageSize: number;
  isLoading?: boolean;
  children?: React.ReactNode;
}

const Orders: React.FC<OrderProps> = ({
  title,
  orders,
  isOpen,
  totalItems,
  activePage,
  onPaginate,
  pageSize,
  isLoading,
  children,
}) => {
  const totalPages = Math.ceil(totalItems / pageSize);

  return isB2b ? (
    <Accordion isOpen={isOpen} title={title} variant="secondary">
      {isLoading ? (
        <OrderOverviewSkeleton pageSize={pageSize} />
      ) : (
        <>
          {children}
          <PagedOrders currentPage={activePage} onPaginate={onPaginate} orders={orders} totalPages={totalPages} />
        </>
      )}
    </Accordion>
  ) : (
    <>
      <h2 className="mb-4 text-lg font-bold">{title}</h2>
      {children}
      <PagedOrders currentPage={activePage} onPaginate={onPaginate} orders={orders} totalPages={totalPages} />
    </>
  );
};

const OrderOverview = () => {
  const { t } = useTranslation('account');
  const { locale } = useRouter();
  const pageSize = 5;

  const { closedOrders, inProcessOrders, openOrders, filters, setFilters, isInitialLoading } = useOrders({
    locale: locale as string,
    pageSize,
  });

  const [searchValue, setSearchValue] = useState('');
  const [previousCounts, setPreviousCounts] = useState<Record<string, number>>({});

  useEffect(() => {
    const newCounts: Record<string, number> = {};

    [inProcessOrders, openOrders, closedOrders].forEach(orderData => {
      if (!orderData.isLoading && orderData?.data?.count !== undefined) {
        newCounts[orderData.type] = orderData.data.count;
      }
    });
    setPreviousCounts(prev => ({ ...prev, ...newCounts }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProcessOrders.data, openOrders.data, closedOrders.data]);

  const ordersToRender = isB2b ? [inProcessOrders, openOrders, closedOrders] : [closedOrders];

  return (
    <>
      <h1 className="mb-3">{t('orders.myOrders')}</h1>
      {!isB2b && <Alert type="info">{t('orders.info')}</Alert>}
      <Presence
        id="orderOverview"
        isLoading={isInitialLoading}
        loader={<OrderOverviewSkeleton pageSize={pageSize} />}
        visible
      >
        <div className="flex flex-col pb-5">
          {ordersToRender.map(orderData => {
            const title = t(`orders.${orderData.type}Orders`);

            if (!orderData?.data?.count && !orderData.isLoading && orderData.type !== 'closed') {
              return null;
            }

            return (
              <Orders
                activePage={orderData.currentPage}
                isLoading={orderData.isLoading}
                isOpen={false}
                key={orderData.type}
                onPaginate={orderData.paginate}
                orders={orderData?.data?.orders ?? []}
                pageSize={pageSize}
                title={
                  isB2b
                    ? `${title} (${
                        orderData.isLoading ? previousCounts[orderData.type] ?? '...' : orderData?.data?.count ?? 0
                      })`
                    : undefined
                }
                totalItems={orderData?.data?.count ?? 0}
              >
                {orderData.type === 'closed' && (
                  <div className="my-5" data-testid="order-search">
                    <SearchBar
                      className="md:max-w-95 mb-10"
                      onClearSearch={() => setFilters({ ...filters, orderId: undefined })}
                      onSearch={() => setFilters({ ...filters, orderId: searchValue })}
                      searchLabel={t('orders.search') ?? ''}
                      setSearchValue={setSearchValue}
                      type="primary"
                      value={searchValue}
                    />
                    <DateFilters
                      collapsable={false}
                      fromDate={filters.fromDate}
                      onSetFilters={setFilters}
                      toDate={filters.toDate}
                    />
                  </div>
                )}
              </Orders>
            );
          })}
          {closedOrders.data?.orders?.length === 0 && (
            <Alert className="mt-2" type="info">
              {t('orders.noResults')}
            </Alert>
          )}
        </div>
      </Presence>
    </>
  );
};

export default OrderOverview;
