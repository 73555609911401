import { getDatesForWeek, getMonday, toHoursAndMinutes } from '@boss/utils';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

export type Translations = {
  title?: string;
  weekdays?: Array<Day>;
  exception?: {
    title: string;
    message: string;
    exceptionallyOpen: string;
    exceptionallyClosed: string;
  };
  closed?: string;
};

type Day = {
  day: string;
  name: string;
};

export type TimeObject = {
  from: number | string;
  till: number | string;
  fromSeconds?: number | null;
  tillSeconds?: number | null;
};

export type DayObject = {
  day: string;
  times: Array<TimeObject>;
};

type ExceptionObject = {
  date: string;
  times: Array<TimeObject>;
};

type Props = {
  defaults: Array<DayObject>;
  exceptions: Array<ExceptionObject>;
  translations: Translations;
  variant?: 'compact' | 'default';
};

type MessageProps = {
  timeframe: DayObject | undefined;
  specialMessage: ExceptionObject;
  translations: Translations;
};

const WrapperStyles = cva('', {
  variants: {
    variant: {
      compact: 'font-raleway font-medium',
      default: '',
    },
  },
});

const BoldStyles = cva('', {
  variants: {
    variant: {
      compact: 'font-extrabold',
      default: 'font-bold',
    },
  },
});

const SpecialMessage = ({ timeframe, specialMessage, translations }: MessageProps) => {
  const checkIfExceptionallyOpened = (time: TimeObject) => {
    return !!(!timeframe && time.from !== 0 && time.till !== 0);
  };

  const checkIfExceptionallyClosed = (time: TimeObject) => {
    let dayHasHours = false;

    timeframe?.times.forEach(time => {
      if (!dayHasHours) {
        dayHasHours = time.from !== 0 || time.till !== 0;
      }
    });

    return dayHasHours && time.from === 0 && time.till === 0;
  };

  const specialMessageDate = new Date(specialMessage.date);

  return (
    <div className="bg-brown-light mb-2.75 px-2.75 body block py-1.5">
      <p>{translations?.exception?.title}</p>
      <div className="flex gap-3">
        <p className="font-bold">
          {specialMessageDate.getDate()}/{specialMessageDate.getMonth() + 1}/{specialMessageDate.getFullYear()}:
        </p>
        {specialMessage.times.map(time => {
          if (checkIfExceptionallyOpened(time)) {
            return (
              <p key={time.from}>
                {translations.exception?.exceptionallyOpen} {toHoursAndMinutes(time.from)}-
                {toHoursAndMinutes(time.till)}
              </p>
            );
          }
          if (checkIfExceptionallyClosed(time)) {
            return <p key={time.from}>{translations.exception?.exceptionallyClosed}</p>;
          }
          return (
            <p key={time.from}>
              {translations.exception?.exceptionallyOpen} {toHoursAndMinutes(time.from)}-{toHoursAndMinutes(time.till)}
            </p>
          );
        })}
      </div>
    </div>
  );
};

const OpeningHours = ({ defaults, exceptions, translations, variant = 'default' }: Props) => {
  const renderSlots = () => {
    const date = new Date();
    const monday = getMonday(date);
    const week = getDatesForWeek(monday);

    const commingExceptions = exceptions.filter(exception => {
      const exceptionDate = new Date(exception.date);
      const today = new Date();
      const nextWeek = new Date(today);

      nextWeek.setDate(today.getDate() + 7);
      return exceptionDate >= today && exceptionDate <= nextWeek;
    });

    return week.map(currentDate => {
      const day = currentDate.getDay() === 0 ? 7 : currentDate.getDay();
      const timeFrame = defaults.find(currentDefault => currentDefault.day === day.toString());
      const specialMessages = commingExceptions.filter(
        currentException => new Date(currentException?.date).getDay() === currentDate.getDay(),
      );

      const weekTranslations = translations.weekdays?.find(trans => trans.day === day.toString());
      const formatCurrentDate = new Date(currentDate.setHours(0, 0, 0));
      const formatToday = new Date(date.setHours(0, 0, 0));
      const daysPassed = formatCurrentDate < formatToday ? true : false;
      const isToday = currentDate.getDay() === new Date().getDay() ? true : false;

      return (
        <div className="flex flex-col" key={day}>
          <div className={`mb-2.75 grid grid-cols-3 items-center ${daysPassed || !timeFrame ? 'text-gray' : ''}`}>
            <span className={twMerge('leading-16 body col-span-1 mr-2 block', isToday ? BoldStyles({ variant }) : '')}>
              {weekTranslations?.name}
            </span>
            <div className="col-span-2 flex">
              {!!timeFrame?.times.length &&
                timeFrame.times.map(time => (
                  <span
                    className={`leading-13 body mr-3 inline-block md:mr-4 ${isToday ? BoldStyles({ variant }) : ''}  `}
                    key={time.from}
                  >
                    {toHoursAndMinutes(time.from)}-{toHoursAndMinutes(time.till)}
                  </span>
                ))}
              {!timeFrame && (
                <span className="leading-13 body mr-4 inline-block" key={day}>
                  {translations?.closed}
                </span>
              )}
            </div>
          </div>
          {specialMessages.length > 0 &&
            specialMessages.map((message, index) => (
              <SpecialMessage key={index} specialMessage={message} timeframe={timeFrame} translations={translations} />
            ))}
        </div>
      );
    });
  };

  return (
    <div className="flex">
      <div className={twMerge(WrapperStyles({ variant }))}>
        {translations.title && (
          <h4 className={twMerge('h3 mb-5.5 block', BoldStyles({ variant }))}> {translations.title}</h4>
        )}
        <div className="flex w-full flex-col">{renderSlots()}</div>
      </div>
    </div>
  );
};

export default OpeningHours;
