import { useTranslation } from 'next-i18next';

import { ColorButton, Presence, Skeleton } from '@boss/ui';

import { useColorByColorId } from '../../../client-queries';
import { useFavorites } from '../../../hooks';

type Props = {
  id: string;
};

const CfColorButton = ({ id }: Props) => {
  const { data: color, isLoading } = useColorByColorId(id);
  const { favorites, toggleFavorite } = useFavorites();
  const { t } = useTranslation();

  return (
    <Presence
      className="h-full"
      id="color-card-presence"
      isLoading={isLoading}
      loader={<Skeleton className="h-25 border-radius-50 pt-5 " />}
      visible
    >
      {color && (
        <ColorButton
          {...color}
          backgroundColor={color.rgb}
          isFavorite={favorites?.COLORS?.includes(id ?? '')}
          onFavorite={() => toggleFavorite('COLORS', id ?? '', color)}
          translations={{ trending: t('trending') ?? '' }}
          variant="secondary"
        />
      )}
    </Presence>
  );
};

export default CfColorButton;
