import type { Component, CtaFields, ISectionFields } from '@boss/types/b2b-b2c';
import { PromotionBanner } from '@boss/ui';

import { SectionMapperProps } from '..';
import { getButtonProps, getPromotionCtaProps } from '../../';

const PromotionBannerMapper = ({ entry, locale, theme, className }: SectionMapperProps) => {
  const getPromotionCta = (
    item: Component,
    variant: 'primary' | 'secondary',
    position?: ISectionFields['ctaPosition'],
  ) => {
    if (item?.__typename === 'promotionCta') {
      let alignment: 'left' | 'right' | undefined;

      if (position === 'bottom-left') {
        alignment = 'left';
      }

      if (position === 'bottom-right') {
        alignment = 'right';
      }

      const promotionCtaProps = {
        ...getPromotionCtaProps(item, theme, locale),
        variant,
      };

      return {
        ...promotionCtaProps,
        ...(alignment && { alignment: alignment }),
      };
    }

    return {
      className: 'bg-green',
      text: 'Wrong content type',
      link: {
        href: '/',
      },
      variant,
    };
  };

  const promotionCta = getPromotionCta(entry.content?.[0], 'primary', entry.ctaPosition);
  const secondaryPromotionCta = getPromotionCta(entry.content?.[1], 'secondary');

  const links = entry.content
    .filter((item): item is CtaFields => item?.__typename === 'cta')
    .map(item => getButtonProps(item, theme, locale));

  const isReversedLayout = entry.displaySectionAs === 'promotion-banner-switched';

  return (
    <PromotionBanner
      className={className}
      links={links}
      promotionCta={promotionCta}
      reverseLayout={isReversedLayout}
      secondaryPromotionCta={secondaryPromotionCta}
    />
  );
};

export default PromotionBannerMapper;
