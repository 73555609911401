import { faCheck, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

import { ColorPickerInstanceProps } from '..';
import { Orb, PaintDrop, SearchBar } from '../../';

const FullWidthColorpicker = ({
  colors,
  onSearch,
  className,
  translations,
  selectedColor,
  onChangeGroupClick,
  searchValue,
  setSearchValue,
  colorType,
}: ColorPickerInstanceProps) => {
  return (
    <div className={twMerge('flex flex-col items-center gap-3 md:mb-4', className)}>
      <h3 className="mb-10 font-medium">{translations.chooseColorFromGroup}</h3>
      <div className="mb-12 grid w-full grid-cols-3 place-items-center gap-5 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-12">
        {colors.map((color, index) => (
          <button
            className="flex flex-col items-center"
            key={`${color.code}-orb-${index}`}
            onClick={() => onChangeGroupClick(color)}
          >
            <Orb size="md" style={{ backgroundColor: color.rgb }} />
            <span className="mt-4">{color.name?.toLowerCase()}</span>
          </button>
        ))}
      </div>

      {selectedColor && (
        <div className="flex items-center gap-2">
          <span className="font-medium">{translations.chosenColor}:</span>
          <span className="small">
            {selectedColor.code}
            {selectedColor.name && selectedColor.code && ' - '}
            {selectedColor.name}
          </span>
        </div>
      )}
      <div className="flex w-full flex-col items-center justify-center md:w-3/4 md:flex-row">
        <div className="mr-20 hidden items-center gap-2 md:flex">
          <div className="relative mr-5">
            <PaintDrop colorCode={selectedColor?.rgb} colorType={colorType} />
            <FontAwesomeIcon
              className="text-gray-dark absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
              icon={faCheck}
            />
            <button
              className="small text-gray-dark mt-2 flex items-center gap-1 whitespace-nowrap underline md:absolute md:left-1/2 md:top-full md:-translate-x-1/2"
              onClick={() => onChangeGroupClick()}
            >
              {translations.changeColor}
              <FontAwesomeIcon icon={faPencil} />
            </button>
          </div>
        </div>
        <SearchBar
          className="border-b-1 text-md bg-inherit p-0"
          onSearch={onSearch}
          searchLabel={translations.searchByColorName}
          setSearchValue={setSearchValue}
          value={searchValue}
        />
      </div>
    </div>
  );
};

export default FullWidthColorpicker;
