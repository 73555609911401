import { IFile, IProduct } from '@boss/services';

const getCharacteristicsItems = (product: IProduct, t: (key: string) => string) => {
  const characteristicsItems: { label: string; value: string }[] = [];

  if (product.finish) {
    characteristicsItems.push({
      label: t('characteristics.finish'),
      value: product.finish,
    });
  }

  if (product.efficiency) {
    characteristicsItems.push({
      label: t('characteristics.efficiency'),
      value: `${product.efficiency} ${t('characteristics.surface')}`,
    });
  }

  if (product.paintableTimeMin) {
    const paintableTimeMax = product.paintableTimeMax ? ` - ${product.paintableTimeMax}` : '';

    characteristicsItems.push({
      label: t('characteristics.dryingTime'),
      value: `${product.paintableTimeMin}${paintableTimeMax}${t('characteristics.hour')}`,
    });
  }

  if (product.numberOfLayers) {
    characteristicsItems.push({
      label: t('characteristics.numberOfLayers'),
      value: `${product.numberOfLayers}`,
    });
  }

  return characteristicsItems;
};

const getProductDetailTitle = (product: IProduct) => product.seoTitle ?? '';

const getTechnicalDocumentInfo = (file: IFile, label: string): { href: string; label: string } => {
  return {
    href: file.url,
    label: label,
  };
};

export { getCharacteristicsItems, getProductDetailTitle, getTechnicalDocumentInfo };
