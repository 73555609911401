import { faPause, faPlay } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import IconButton from '../../IconButton';
import Image from '../../Image';
import Video from '../../Video';
import { HeroProps } from '../index';

const HeroSecondaryWithVideo = ({
  backgroundColor,
  className,
  image,
  imageAlt,
  title,
  titleClassName,
  videoSrc,
}: HeroProps) => {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const handleMouseMoves = (flip: boolean) => {
    if (videoPlaying) {
      setShowButton(flip);
    }
  };

  return (
    <div
      className={twMerge(
        'relative flex min-h-[300px] w-full items-center justify-center overflow-hidden bg-gray-600 py-4 md:min-h-[500px]',
        className,
      )}
      onMouseEnter={() => handleMouseMoves(true)}
      onMouseLeave={() => handleMouseMoves(false)}
      style={{ backgroundColor }}
    >
      {videoSrc && (
        <div className="absolute inset-x-0 inset-y-0">
          <Video
            className="h-full"
            onEnded={() => {
              setVideoPlaying(false);
              setShowButton(true);
            }}
            playing={videoPlaying}
            showControls={false}
            src={videoSrc}
          />
        </div>
      )}
      {image && (
        <div className="absolute inset-x-0 inset-y-0">
          {image && !videoPlaying && <Image alt={imageAlt} className="object-fit" src={image} />}
        </div>
      )}
      <div className="relative flex flex-col items-center pb-8 md:w-1/3">
        <h1 className={twMerge('h1 pointer-events-none p-4 text-center text-white ', titleClassName)}>{title}</h1>
        {showButton && (
          <div
            className="absolute bottom-0 z-20 self-center text-black"
            onClick={() => {
              setVideoPlaying(!videoPlaying);
            }}
          >
            <IconButton chip icon={videoPlaying ? faPause : faPlay} />
          </div>
        )}
      </div>
    </div>
  );
};

export default HeroSecondaryWithVideo;
