import { useTranslation } from 'next-i18next';
import { ComponentProps, useState } from 'react';

import { IProduct, IStore } from '@boss/services';
import { Color, ColorGroup, IArticle, ISearchProduct } from '@boss/types/b2b-b2c';
import { calcAmountOfpaintForSurface } from '@boss/utils';

import { PaintGuideResultCard, SurfaceCalculatorModal } from '../../components';

type Product = IProduct | ISearchProduct;

type Props = {
  aftertreatmentProduct?: Product | null | undefined;
  finishProduct?: Product | null | undefined;
  pretreatmentProduct?: Product | null | undefined;
  supportProduct: Product | null | undefined;
  wallpaperApplicationProduct?: Product | null | undefined;
  withCounter?: boolean;
  preCalculatedSurface?: number;
  store: IStore | undefined;
  colorGroups: ColorGroup[] | null;
  selectedColor?: Color | null;
  showBaseColors?: boolean;
  selectedColorBase?: string;
};

type ResultProps = {
  counter: number;
  product: Product;
  subtitle: string;
  store: IStore | undefined;
  totalPaintNeeded: number;
  onCalculateSurface?: (articles: IArticle[]) => void;
  withCounter?: boolean;
  colorGroups: ColorGroup[] | null;
  selectedColor?: Color | null;
  showBaseColors?: boolean;
  selectedColorBase?: string;
};

const Result = ({
  withCounter = false,
  totalPaintNeeded,
  counter,
  product,
  subtitle,
  store,
  onCalculateSurface,
  colorGroups,
  selectedColor,
  showBaseColors = false,
  selectedColorBase,
}: ResultProps) => (
  <div className="xl:gap-x-30 my-10 lg:flex">
    <div className="lg:min-w-48 mb-5 flex items-baseline md:flex-col">
      {withCounter && <h2 className="lg:text-40 mb-3 mr-3 text-xl">{counter}.</h2>}
      <h4>{subtitle}</h4>
    </div>
    <PaintGuideResultCard
      amountOfPaint={totalPaintNeeded}
      colorGroups={colorGroups}
      onCalculateSurface={onCalculateSurface}
      preChosenColor={selectedColor}
      preChosenColorBase={selectedColorBase}
      product={product}
      selectedStore={store}
      showBaseColors={showBaseColors}
    />
  </div>
);

type ProductType = 'pretreatment' | 'wallpaper' | 'support' | 'finish' | 'aftertreatment';

interface ProductConfig {
  product: Product | null | undefined;
  translationKey: string;
  type: ProductType;
}

const PaintguideResultsWrapper = ({
  preCalculatedSurface = 0,
  finishProduct,
  supportProduct,
  pretreatmentProduct,
  wallpaperApplicationProduct,
  aftertreatmentProduct,
  ...props
}: Props) => {
  const { t } = useTranslation('paintguide');

  const [showSurfaceCalculatorModal, setShowSurfaceCalculatorModal] = useState(false);
  const [calculatedResults, setCalculatedResults] = useState<
    Record<string, ComponentProps<typeof SurfaceCalculatorModal>['calculated']>
  >({});
  const [articles, setArticles] = useState<IArticle[]>();
  const [selectedProduct, setSelectedProduct] = useState<Product | null>();

  const productConfigs: ProductConfig[] = [
    { product: pretreatmentProduct, translationKey: 'pretreatment', type: 'pretreatment' },
    { product: wallpaperApplicationProduct, translationKey: 'wallpaperApplication', type: 'wallpaper' },
    { product: supportProduct, translationKey: 'supportLayer', type: 'support' },
    { product: finishProduct, translationKey: 'finshedLayer', type: 'finish' },
    { product: aftertreatmentProduct, translationKey: 'aftertreatment', type: 'aftertreatment' },
  ];

  const calculatePaintNeeded = (product: Product | null | undefined) => {
    if (!product?.consumptionOptions?.length || !preCalculatedSurface) {
      return 0;
    }
    const activeConsumption = product.consumptionOptions[0].usage['average'];

    return calcAmountOfpaintForSurface(activeConsumption, preCalculatedSurface);
  };

  const getCounter = (productType: ProductType) => {
    return productConfigs.filter(config => config.product).findIndex(config => config.type === productType) + 1;
  };

  const createCalculateSurfaceHandler = (product: Product | null | undefined) => {
    if (!product?.consumptionOptions?.length) {
      return undefined;
    }

    return (articles: IArticle[]) => {
      setShowSurfaceCalculatorModal(true);
      setArticles(articles);
      setSelectedProduct(product);
    };
  };

  return (
    <>
      {productConfigs.map(
        ({ product, translationKey, type }) =>
          product && (
            <Result
              key={product.id}
              {...props}
              counter={getCounter(type)}
              onCalculateSurface={createCalculateSurfaceHandler(product)}
              product={product}
              subtitle={t(translationKey)}
              totalPaintNeeded={calculatedResults[product.id]?.totalPaintNeeded ?? calculatePaintNeeded(product)}
            />
          ),
      )}
      {showSurfaceCalculatorModal && selectedProduct && (
        <SurfaceCalculatorModal
          articles={articles}
          calculated={calculatedResults[selectedProduct.id]}
          consumptionOptions={selectedProduct.consumptionOptions ?? []}
          onClose={() => {
            setShowSurfaceCalculatorModal(false);
            setSelectedProduct(null);
          }}
          onConfirm={calc => {
            setCalculatedResults(prev => ({
              ...prev,
              [selectedProduct.id]: calc,
            }));
            setShowSurfaceCalculatorModal(false);
            setSelectedProduct(null);
          }}
          preCalculated={preCalculatedSurface}
          product={selectedProduct}
        />
      )}
    </>
  );
};

export default PaintguideResultsWrapper;
