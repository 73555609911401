import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { Alert, Quotation, Repeat, Skeleton } from '@boss/ui';
import { downloadFile } from '@boss/utils';

import { useQuotationFile, useQuotations } from '../../../client-queries';

type Props = {
  className?: string;
};

const Quotations = ({ className }: Props) => {
  const { t } = useTranslation('account');
  const [fileId, setFileId] = useState('');
  const { isLoading, data: quotations } = useQuotations();
  const { data: fileLink } = useQuotationFile(fileId);

  const handleDownload = (id: string) => {
    setFileId(id);
  };

  useEffect(() => {
    if (!fileLink) {
      return;
    }

    downloadFile(fileLink, fileLink);
  }, [fileLink]);

  return (
    <div className={className}>
      <h1 className="mb-5">{t('quotation.title')}</h1>
      <div className="mb-5 flex flex-col gap-7">
        {!isLoading &&
          !!quotations?.length &&
          quotations?.map(quotation => (
            <Quotation
              key={quotation.id}
              onHandleDownload={() => handleDownload(quotation.id)}
              translations={t('quotation', { ns: 'account', returnObjects: true })}
              {...quotation}
            />
          ))}
        {!isLoading && !quotations?.length && <Alert type="info">{t('invoices.notifications.noQuotations')}</Alert>}
        {isLoading && (
          <div className="flex flex-wrap items-center justify-between gap-5">
            <Repeat amount={5}>
              <Skeleton className="h-40 w-full" />
            </Repeat>
          </div>
        )}
      </div>
    </div>
  );
};

export default Quotations;
