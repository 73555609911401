import { faLaptop } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { getOrderTypeByStatus } from '@boss/constants/b2b-b2c';
import { Alert, Button, Image, Orb, Presence } from '@boss/ui';
import { formatDate } from '@boss/utils';

import { Address } from './b2b';
import { useArticlesByIds, useOrder, useProductsById } from '../../../../client-queries';
import { OrderDetailSkeleton } from '../../../../components';
import { useProductMap } from '../../../../hooks';

type Props = {
  orderId: string;
};

const OrderDetail = ({ orderId }: Props) => {
  const { t } = useTranslation('account');
  const { locale } = useRouter();
  const { mapProductSlug } = useProductMap();

  const { data: order, isLoading: orderLoading, isError, isSuccess: orderLoaded } = useOrder(locale as string, orderId);

  const invoiceAddress = order?.addresses.find(address => address.role?.toUpperCase() === 'INVOICE' && address.street);
  const deliveryAddress = order?.addresses.find(
    address => address.role?.toUpperCase() === 'DELIVERY' && address.street,
  );

  const fallbackAddress = order?.addresses[0];

  const statusTranslations = t('orderStatus', { returnObjects: true }) as { [key: string]: string };
  const statusType = order?.salesStatus ? getOrderTypeByStatus(order?.salesStatus) : undefined;

  const orderItemIds = useMemo(() => {
    const itemIds = order?.lines?.filter(line => !!line.itemId).map(line => line.itemId!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return [...new Set(itemIds ?? [])];
  }, [order]);

  /*
   * Todo: remove the article fetching step once the productId is available in the order lines
   */
  const { data: articles, isSuccess: articlesLoaded, isLoading: articlesLoading } = useArticlesByIds(orderItemIds);

  const uniqueProductIds = articlesLoaded
    ? [
        ...new Set(
          articles
            ?.reduce((prev: string[], article) => (article.productId ? [...prev, article.productId] : prev), [])
            .flat(),
        ),
      ]
    : [];

  const { data: products } = useProductsById(uniqueProductIds, orderLoaded);

  const isLoading = orderLoading || articlesLoading;

  return (
    <>
      <Presence id="orderDetails" isLoading={isLoading} loader={<OrderDetailSkeleton />} visible={!!order}>
        <h2>{t('orderDetail.title')}</h2>
        {order && (
          <>
            <div className="grid grid-cols-2 gap-3 py-5 md:flex md:gap-5">
              {order.online && (
                <div>
                  <span className=" flex items-center">
                    <FontAwesomeIcon className="mr-2" icon={faLaptop} /> {t('orderDetail.online')}
                  </span>
                </div>
              )}

              <span>
                <strong>{t('orderDetail.date')}: </strong> {formatDate(order.createdDatetime)}
              </span>

              {order.salesId && (
                <span>
                  <strong>{t('orderDetail.orderNumber')}: </strong> {order.salesId}
                </span>
              )}

              <span>
                <strong>{t('orderDetail.status')}: </strong>
                <span className="text-blue">{statusTranslations[statusType ?? ''] ?? order.salesStatus}</span>
              </span>
            </div>
            {order.trackAndTrace && (
              <Button
                className="ml-auto"
                href={order.trackAndTrace}
                label={t('orderDetail.trackandtrace')}
                type="link"
              />
            )}
            <div className="shadow-m my-5 flex flex-col gap-4 rounded p-5">
              {order.lines.map((line, index) => {
                /*
                 * Todo: get productId from the order line instead of the fetched articles (see above todo)
                 */
                const productId = articles?.find(product => product?.id === line.itemId);
                const product = products?.find(product => product?.id === productId?.productId);

                return (
                  <div
                    className={`${index !== order.lines.length - 1 ? 'border-b-1' : ''} grid grid-cols-9 gap-4 py-4`}
                    key={line.itemId}
                  >
                    <div className="col-span-2 md:col-span-1 md:h-20 md:w-20">
                      {line.imageUrl && (
                        <Image
                          alt={`Product image for ${line.name}`}
                          className="object-contain"
                          height={50}
                          src={line.imageUrl}
                          useNext
                          width={50}
                        />
                      )}
                    </div>
                    <div className="col-span-4">
                      <div className="align-left flex flex-col gap-2 md:flex-row md:items-center">
                        <strong>{line.name}</strong>
                        <span className="flex gap-2">
                          <span className="text-sm">
                            {line.colorCode && line.colorName
                              ? `${line.colorCode} - ${line.colorName}`
                              : line.colorCode || line.colorName || ''}
                          </span>
                          {line.rgb && <Orb size="xs" style={{ backgroundColor: line.rgb }} />}
                        </span>
                      </div>
                    </div>
                    <div className="col-span-4 md:col-span-2">
                      <span>
                        {t('amount', { ns: 'common' })}: {line.quantity.salesQty} {line.quantity.salesUnit}
                      </span>
                    </div>
                    <div className="col-span-12 md:col-span-2">
                      {product && (
                        <Button
                          href={mapProductSlug({
                            colorId: line.colorId,
                            colorCode: line.colorCode,
                            colorGroups: line.colorTypeGroups,
                            id: product.id,
                            productName: line.name,
                          })}
                          iconPosition="left"
                          label={t('orderDetail.reorder')}
                          type="primary"
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="my-10 flex gap-4">
              {deliveryAddress && <Address address={deliveryAddress} title={t('orderDetail.deliveryAddress')} />}
              {invoiceAddress && <Address address={invoiceAddress} title={t('orderDetail.invoiceAddress')} />}
              {!deliveryAddress && !invoiceAddress && fallbackAddress && (
                <Address address={fallbackAddress} title={t('orderDetail.deliveryAddress')} />
              )}
            </div>
          </>
        )}
      </Presence>
      {isError && (
        <Presence id={`$error-message-presence`} visible>
          <Alert className="break-words" iconSize="xl" type="error">
            {t('orderDetail.fetchError')}
          </Alert>
        </Presence>
      )}
    </>
  );
};

export default OrderDetail;
