import { cva } from 'class-variance-authority';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { usePagination } from '@boss/hooks';
import { IAdvice, TAdviceType } from '@boss/services/client';
import { AdviceCard, Alert, Pagination, Repeat, Skeleton } from '@boss/ui';
import { formatDate } from '@boss/utils';

import { useAdvices } from '../../../client-queries';
import { AdviceFilters } from '../../../components';
import { useAdvices as useSortedAdvices } from '../../../hooks';
import { isB2b } from '../../../utils';

type Props = {
  className?: string;
};

type Filters = {
  sortDirection: 'asc' | 'desc';
  filterTypes: TAdviceType[];
  fromDate?: string;
  toDate?: string;
};

const TitleStyles = cva('', {
  variants: {
    isB2b: {
      true: 'mb-5',
      false: 'mb-15',
    },
  },
});

const Advices = ({ className }: Props) => {
  const ITEMS_PER_PAGE = 5;
  const { t } = useTranslation('account');
  const { locale } = useRouter();

  const { data: advices, isError, isLoading } = useAdvices(locale as string);

  const [sortAndFilterOptions, setSortAndFilterOptions] = useState<Filters>({
    sortDirection: 'desc',
    filterTypes: ['CA', 'TA'],
    fromDate: undefined,
    toDate: undefined,
  });

  const sortedAdvices = useSortedAdvices({
    advices: advices ?? [],
    sortDirection: sortAndFilterOptions.sortDirection,
    filterTypes: sortAndFilterOptions.filterTypes,
    fromDate: sortAndFilterOptions.fromDate,
    toDate: sortAndFilterOptions.toDate,
  });

  const { currentPage, paginate, currentItems, totalPages } = usePagination<IAdvice>(ITEMS_PER_PAGE, sortedAdvices);

  return (
    <div className={className}>
      <h1 className={TitleStyles({ isB2b: isB2b })}>{t('advices.title')}</h1>
      <div className="mb-5 flex flex-col gap-7">
        {isB2b && (
          <AdviceFilters
            onSetFilters={filters => {
              setSortAndFilterOptions({
                ...sortAndFilterOptions,
                sortDirection: filters.order,
                filterTypes: filters.types,
                ...filters,
              });
            }}
            order={sortAndFilterOptions.sortDirection}
          />
        )}
        {!!isError && <Alert type="error">{t('advices.notifications.error')}</Alert>}
        {!isLoading &&
          !!currentItems?.length &&
          currentItems?.map(advice => (
            <div key={advice.adviceid}>
              {advice.date && (
                <div className="mb-2 text-right">
                  <strong>{t('advices.adviceCard.date')}:</strong> {formatDate(advice.date)}
                </div>
              )}
              <AdviceCard
                address={advice.worksite}
                adviceType={advice.advicetype === 'TA' ? 'technical' : 'color'}
                downloadLink={advice.dossierlink}
                showLabel={isB2b}
                translations={{
                  label: t(`advices.adviceCard.type.${advice.advicetype === 'TA' ? 'technical' : 'colors'}`),
                  download: t('advices.adviceCard.download'),
                }}
              />
            </div>
          ))}
        {!isLoading && !currentItems?.length && <Alert type="info">{t('advices.notifications.noAdvices')}</Alert>}
        {isLoading && (
          <div className="flex flex-wrap items-center justify-between gap-5">
            <Repeat amount={ITEMS_PER_PAGE}>
              <Skeleton className="h-40 w-full" />
            </Repeat>
          </div>
        )}
        {totalPages > 1 && <Pagination currentPage={currentPage} onPaginate={paginate} totalPages={totalPages} />}
      </div>
    </div>
  );
};

export default Advices;
