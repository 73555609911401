import { BLOCKS } from '@contentful/rich-text-types';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

import { useRouter } from '@boss/hooks';
import { RichText } from '@boss/rich-text';
import { PromotionCtaFields } from '@boss/types/b2b-b2c';
import { Cta, Section } from '@boss/ui';

import { getCtaHref } from '../../../utils';

type Props = {
  callToActions: PromotionCtaFields[];
  className?: string;
};

const CtaCardsSection = ({ callToActions, className }: Props) => {
  const { locale } = useRouter();

  if (!callToActions.length) {
    return null;
  }

  return (
    <Section
      className={twMerge('md:py-20', className)}
      content={
        <div className="grid gap-6 md:grid-cols-3" data-testid="promotionCTA">
          {callToActions?.map((action, index) => (
            <Cta
              body={
                <RichText
                  className="flex w-full flex-col gap-4"
                  content={action.text}
                  mapperOptions={{ locale }}
                  renderNode={{
                    [BLOCKS.HEADING_4]: (_, children) => <h4>{children}</h4>,
                    [BLOCKS.UL_LIST]: (_, children) => <ul className="w-full list-none text-left">{children}</ul>,
                    [BLOCKS.LIST_ITEM]: (_, children) => (
                      <li className="flex- mb-4 flex items-center justify-self-start">
                        <FontAwesomeIcon className="text-blue mr-4" icon={faCheck} />
                        {children}
                      </li>
                    ),
                    [BLOCKS.PARAGRAPH]: (_, children) => <p className="">{children}</p>,
                  }}
                />
              }
              className="flex flex-col gap-4 p-4"
              href={getCtaHref(action.cta, locale)}
              {...action.cta}
              key={`cta-${action.id ?? index}`}
              type="enriched"
            />
          ))}
        </div>
      }
    />
  );
};

export default CtaCardsSection;
