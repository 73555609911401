import { COLLAPSE_GRID_ANIMATE, COLLAPSE_GRID_INITIAL, getCollapsePropsWithOffset } from '@boss/constants/b2b-b2c';
import { SimpleColor } from '@boss/types/b2b-b2c';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ColorPickerModalProps } from '..';
import {
  Alert,
  Button,
  ColorGroupButton,
  ColorGroupButtonSkeleton,
  ColorsGrid,
  ColorsGridSkeleton,
  Divider,
  Modal,
  Presence,
  Repeat,
  SearchBar,
} from '../..';

const DesktopColorPickerModal = ({
  activeColorGroup,
  activeColors,
  recentViewedColors,
  premixedColors,
  callToActions,
  className,
  colorGroups,
  handleShowMore,
  handleShowMoreSearch,
  searchValue,
  hasShowMore,
  hasShowMoreSearch,
  onChangeColorClick,
  onClose,
  onColorGroupClick,
  onFavorite,
  onSearch,
  searchResults,
  selectedColor,
  showMore,
  showMoreSearch,
  translations,
  setSearchValue,
  hasSearched,
  colorsLoading,
  searchLoading,
  favoriteColorIds,
  colorGroupsLoading,
  disclaimer,
  colorType,
  defaultColorBase,
  premixedColorBase,
  hideRecentColors,
  hideColorGroups,
  favoriteColors,
  variant = 'primary',
}: ColorPickerModalProps) => {
  const [expand, setExpand] = useState(false);

  const commonColorGridProps = {
    colorType,
    favoriteColorIds,
    onChangeColorClick: (color: SimpleColor) => onChangeColorClick(color, defaultColorBase),
    onFavorite,
    selectedColor,
    translations,
    variant: 'secondary' as const,
  };

  return (
    <Modal
      className={twMerge('rounded-b-modal rounded-l-modal flex h-full flex-col gap-10 overflow-y-auto', className)}
      onClose={onClose}
    >
      <h2 className="mr-5">{translations.title}</h2>
      <div className="flex flex-col gap-5">
        {!!premixedColors?.length && (
          <Presence
            animate={COLLAPSE_GRID_ANIMATE}
            exit={{}} // Overwriting default exit animation
            id="color-picker-overview-presence"
            initial={COLLAPSE_GRID_INITIAL}
            visible={!!premixedColors?.length}
          >
            <ColorsGrid
              {...commonColorGridProps}
              colors={premixedColors}
              onChangeColorClick={(color: SimpleColor) => onChangeColorClick(color, premixedColorBase)}
              subtitle={translations.premixedColorsSubtitle}
              title={translations.premixedColors}
            />
          </Presence>
        )}

        {!!favoriteColors?.length && variant === 'primary' && (
          <Presence
            animate={COLLAPSE_GRID_ANIMATE}
            exit={{}} // Overwriting default exit animation
            id="color-picker-overview-presence"
            initial={COLLAPSE_GRID_INITIAL}
            visible={!!favoriteColors?.length}
          >
            <ColorsGrid {...commonColorGridProps} colors={favoriteColors} title={translations.favorites} />
          </Presence>
        )}
        {disclaimer && (
          <div className="flex flex-col gap-2">
            <Alert type="info">{disclaimer}</Alert>
          </div>
        )}
        {!hideColorGroups && (
          <>
            <>
              {variant === 'primary' && <span className="font-bold">{translations.searchLabel}</span>}
              <SearchBar
                className="w-full md:max-w-full"
                onSearch={onSearch}
                searchLabel={translations.searchLabel}
                setSearchValue={setSearchValue}
                type="primary"
                value={searchValue}
              />
            </>

            <Presence
              id="color-picker-search-presence"
              {...getCollapsePropsWithOffset('-1.25rem')}
              isLoading={searchLoading}
              loader={<ColorsGridSkeleton amount={8} variant="secondary" />}
              visible={hasSearched}
            >
              <div className="flex flex-col gap-10">
                <ColorsGrid
                  {...commonColorGridProps}
                  colors={searchResults}
                  handleShowMore={handleShowMoreSearch}
                  hasShowMore={hasShowMoreSearch}
                  showMore={showMoreSearch}
                  title={translations.results}
                />
                <Divider />
                <button className="text-blue-dark flex items-center gap-2" onClick={() => setExpand(expand => !expand)}>
                  {expand ? translations.hideLabel : translations.showLabel}
                  <FontAwesomeIcon className={expand ? 'rotate-180' : ''} icon={faChevronDown} />
                </button>
              </div>
            </Presence>
          </>
        )}
      </div>
      {!hideColorGroups && (
        <>
          <Presence
            animate={COLLAPSE_GRID_ANIMATE}
            exit={{}} // Overwriting default exit animation
            id="color-picker-overview-presence"
            initial={COLLAPSE_GRID_INITIAL}
            visible={!hasSearched || (hasSearched && expand)}
          >
            <div className="flex flex-col gap-4">
              {variant === 'primary' && <span className="mb-5 font-bold">{translations.byColorGroup}</span>}
              <div className="gap-15 flex">
                <Presence
                  className="flex flex-col gap-4"
                  id="modal-color-group-presence"
                  isLoading={colorGroupsLoading}
                  loader={
                    <div className="min-w-40 flex flex-col gap-4">
                      <Repeat amount={10}>
                        <ColorGroupButtonSkeleton />
                      </Repeat>
                    </div>
                  }
                  visible
                >
                  {colorGroups?.map(colorGroup => (
                    <ColorGroupButton
                      active={colorGroup.code === activeColorGroup?.code}
                      backgroundColor={colorGroup.rgb}
                      key={colorGroup.code}
                      name={colorGroup.name ?? colorGroup.code}
                      onClick={() => {
                        onColorGroupClick(colorGroup);
                        document.getElementById('color-picker-colors-presence')?.scrollIntoView({ behavior: 'smooth' });
                      }}
                      variant="secondary"
                    />
                  ))}
                </Presence>
                <Presence
                  className="h-full w-full"
                  id="color-picker-colors-presence"
                  isLoading={colorsLoading}
                  loader={<ColorsGridSkeleton variant="secondary" />}
                  visible
                >
                  <ColorsGrid
                    {...commonColorGridProps}
                    colors={activeColors}
                    handleShowMore={handleShowMore}
                    hasShowMore={hasShowMore}
                    showMore={showMore}
                    title={variant === 'secondary' ? translations.subtitle : undefined}
                  />
                </Presence>
              </div>
            </div>
          </Presence>

          {!!callToActions?.length && (
            <div className="flex justify-end gap-6">
              {callToActions.map((callToAction, index) => (
                <Button
                  className={
                    callToAction.highlight
                      ? ''
                      : 'text-brown-dark border-brown-dark border-1 hover:bg-brown-dark bg-white hover:text-white'
                  }
                  key={`${callToAction.label}-${index}`}
                  label={callToAction.label}
                  onClick={callToAction.onClick}
                  type="primary"
                />
              ))}
            </div>
          )}
        </>
      )}
      {!hideRecentColors && !!recentViewedColors?.length && (
        <Presence
          animate={COLLAPSE_GRID_ANIMATE}
          exit={{}} // Overwriting default exit animation
          id="color-picker-overview-presence"
          initial={COLLAPSE_GRID_INITIAL}
          visible={!!recentViewedColors?.length}
        >
          <ColorsGrid {...commonColorGridProps} colors={recentViewedColors} title={translations.recentColors} />
        </Presence>
      )}
    </Modal>
  );
};

export default DesktopColorPickerModal;
