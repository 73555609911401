import { ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';
import { useElementSize } from 'usehooks-ts';

import { Image, Orb } from '..';
import Popper from '../Popper';

type Pointer = {
  focalPoint: {
    x: number;
    y: number;
  };
  floatingContent: ReactElement;
};

type Props = {
  id?: string;
  alt: string;
  src: string;
  height: number;
  width: number;
  pointers: Pointer[];
  className?: string;
  imageClassName?: string;
};

function alignOriginalWithVisibleAspectRatio(
  originalWidth: number,
  originalHeight: number,
  visibleWidth: number,
  visibleHeight: number,
) {
  const originalAspectRatio = originalWidth / originalHeight;
  const visibleAspectRatio = visibleWidth / visibleHeight;

  let alignedOriginalWidth = originalWidth;
  let alignedOriginalHeight = originalHeight;

  if (visibleAspectRatio < originalAspectRatio) {
    // Adjust original width to maintain original aspect ratio
    alignedOriginalWidth = originalHeight * visibleAspectRatio;
  } else if (visibleAspectRatio > originalAspectRatio) {
    // Adjust original height to maintain original aspect ratio
    alignedOriginalHeight = originalWidth / visibleAspectRatio;
  }

  return {
    alignedOriginalWidth,
    alignedOriginalHeight,
  };
}

const ImageWithPointers = ({ id, className, imageClassName, height, width, alt, src, pointers }: Props) => {
  const [imageWrapperRef, { width: wrapperWidth, height: wrapperHeight }] = useElementSize();

  const { alignedOriginalWidth, alignedOriginalHeight } = alignOriginalWithVisibleAspectRatio(
    width,
    height,
    wrapperWidth,
    wrapperHeight,
  );

  const croppedWidth = (width - alignedOriginalWidth) / 2;
  const croppedHeight = (height - alignedOriginalHeight) / 2;

  const widthRatio = alignedOriginalWidth / wrapperWidth;
  const heightRatio = alignedOriginalHeight / wrapperHeight;

  return (
    <div className={twMerge('relative h-full overflow-hidden', className)} ref={imageWrapperRef}>
      <Image alt={alt} className={imageClassName} src={src} width={500} />
      {!!wrapperWidth &&
        !!wrapperHeight &&
        pointers.map(pointer => {
          const xPos = (pointer.focalPoint.x - croppedWidth) / widthRatio;
          const yPos = (pointer.focalPoint.y - croppedHeight) / heightRatio;

          return (
            <div
              className="absolute"
              key={`${id ?? alt}-${pointer.focalPoint.x}-${pointer.focalPoint.y}`}
              style={{ left: xPos, top: yPos }}
            >
              <Popper
                arrowClassName="fill-white"
                button={
                  <button>
                    <Orb className="border-blue-dark border-5 h-5 w-5 bg-white" />
                  </button>
                }
                floating={<div>{pointer.floatingContent}</div>}
                offset={10}
                placement="left"
                showArrow
              />
            </div>
          );
        })}
    </div>
  );
};

export default ImageWithPointers;
