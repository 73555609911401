import { useEffect, useState } from 'react';

import { useApiPagination } from '@boss/hooks';

import { useOrders as _useOrders, useInProcessOrders } from '../../client-queries';
import { isB2b } from '../../utils';

interface Props {
  locale: string;
  pageSize: number;
}

const useOrderData = (
  locale: string,
  pageSize: number,
  filters: {
    fromDate?: string;
    toDate?: string;
    orderId?: string;
  },
) => {
  const pagination = useApiPagination(pageSize);
  /* altough the useQuery hook exports an isInitialLoading property, 
    it doesn't seem to provide the desired effect since it becomes true again 
    when the data is refetched, so we need to create a custom state to handle this
  */
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);

  const { data, isLoading } = _useOrders(locale, {
    limit: pagination.limit,
    offset: pagination.offset,
    ...filters,
  });

  useEffect(() => {
    if (!isLoading) {
      setIsInitialLoaded(true);
    }
  }, [data, isLoading]);

  return { ...pagination, isLoading, isInitialLoaded, data, type: 'closed' };
};

const _useInProcessOrders = (locale: string, pageSize: number, type: 1 | 2) => {
  /* This hook is similar to the useOrderData hook, but it's used for inProcess orders 
     I tried to create a custom hook to avoid code duplication, but it's a bit tricky
     to be typescript compliant, so I decided to keep it separated
  */
  const pagination = useApiPagination(pageSize);
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);

  const { data, isLoading } = useInProcessOrders(
    locale,
    {
      limit: pagination.limit,
      offset: pagination.offset,
      type,
    },
    isB2b,
  );

  useEffect(() => {
    if (!isLoading) {
      setIsInitialLoaded(true);
    }
  }, [data, isLoading]);

  return { ...pagination, isLoading, isInitialLoaded, data, type: type === 1 ? 'inProcess' : 'open' };
};

export const useOrders = ({ locale, pageSize = 5 }: Props) => {
  const [filters, setFilters] = useState<{ fromDate?: string; toDate?: string; orderId?: string }>({});
  const closedOrders = useOrderData(locale as string, pageSize, filters);
  const inProcessOrders = _useInProcessOrders(locale as string, pageSize, 1);
  const openOrders = _useInProcessOrders(locale as string, pageSize, 2);

  const isInitialLoading = isB2b
    ? !closedOrders.isInitialLoaded || !inProcessOrders.isInitialLoaded || !openOrders.isInitialLoaded
    : !closedOrders.isInitialLoaded;

  return {
    closedOrders,
    inProcessOrders,
    openOrders,
    filters,
    setFilters,
    isInitialLoading,
  };
};

export default useOrders;
