import { Modal } from '@boss/ui';

import { MagazineCardForm } from '../Form';

interface Translations {
  title: string;
  description?: string;
  subscribe: string;
}

type Props = {
  onCloseModal: (value: boolean) => void;
  onSubmit: (values: { email: string; firstName: string; lastName: string; subscribeNewsletter: boolean }) => void;
  isLoading: boolean;
  translations: Translations;
};

const SubscribeModal = ({ onCloseModal: handleCloseModal, onSubmit: handleSubmit, isLoading, translations }: Props) => {
  return (
    <Modal className="md:max-w-[500px]" onClose={() => handleCloseModal(false)}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <p className="text-lg font-medium">{translations.title}</p>
          {translations.description && <p className="text-sm text-gray-600">{translations.description}</p>}
        </div>

        <MagazineCardForm isSubmitting={isLoading} onSubmit={handleSubmit} submitButtonLabel={translations.subscribe} />
      </div>
    </Modal>
  );
};

export default SubscribeModal;
