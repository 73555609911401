import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { IAccountOrder } from '@boss/services/client';
import { OrderPreview, Pagination } from '@boss/ui';
import { formatDate } from '@boss/utils';
import { getAccountPageSlugByType } from '@boss/utils';

import { COLOR_TYPE } from '../../constants';
import { DeliveryMethodEnum } from '../../hooks/use-checkout/use-checkout';
import { isB2b, ordersVariant } from '../../utils';

interface Props {
  orders: IAccountOrder[];
  currentPage: number;
  onPaginate: (pageNumber: number) => void;
  totalPages: number;
  title?: string;
}

const PagedOrders = ({ orders, currentPage, onPaginate: handlePagination, totalPages, title }: Props) => {
  const { t } = useTranslation('account');
  const { locale } = useRouter();

  const translations = {
    date: t('orders.date'),
    paid: t('orders.paid'),
    orderNumber: t('orders.orderNumber'),
    download: t('orders.download'),
    detailPage: t('orders.detailPage'),
    warning: t('orders.warning'),
    readMore: t('orders.readMore'),
    products: t('orders.products'),
    status: t('orders.status'),
    online: t('orders.online'),
    on: t('orders.on'),
    orderDate: t('orders.orderDate'),
    trackAndTrace: t('orderDetail.trackandtrace'),
  };

  const orderPage = getAccountPageSlugByType('ORDERS', locale as string);

  return (
    <div className="flex flex-col">
      {title && <h2 className="text-2xl font-bold">{title}</h2>}
      <div>
        <Pagination className="mb-3" currentPage={currentPage} onPaginate={handlePagination} totalPages={totalPages} />
        {orders?.map(order => (
          <OrderPreview
            className="mb-8"
            customerReference={order.customerRef}
            deliveryLocation={order.deliveryLocation}
            deliveryStatus={order.deliveryMethod === DeliveryMethodEnum.StandardDelivery ? 'onTheMove' : 'ready'}
            distributionDescription={order.distributionDescription}
            href={`${orderPage}/${order.id}`}
            key={order.salesId}
            online={order.online}
            orderCreationDate={formatDate(order.createdDatetime)}
            orderLines={order.lines?.map(line => ({
              name: line.name,
              id: line.itemId ?? '',
              quantity: line.quantity.tobeDeliveredQty ?? 0,
              unit: line.quantity.salesUnit,
              colorName: line.colorName,
              colorCode: line.colorCode,
              imageUrl: line.imageUrl,
              myMx: line.colorTypeGroups?.includes(COLOR_TYPE.MYMX),
            }))}
            orderNumber={order.salesId}
            paid={order.paid}
            receiptDateRequested={formatDate(order.receiptDateRequested)}
            status={isB2b ? order.salesStatus : order.deliveryStatus}
            tourInfo={order.tourInfo}
            trackAndTrace={order.trackAndTrace}
            translations={translations}
            variant={ordersVariant}
          />
        ))}
      </div>
    </div>
  );
};

export default PagedOrders;
