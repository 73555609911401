// eslint-disable-next-line
import { v4 as uuid } from 'uuid';

import { IProduct } from '@boss/services';
import type { Color, Nullable, SectionFields, Theme } from '@boss/types/b2b-b2c';
import { Carousel } from '@boss/ui';

import ColoraStoreFinder from './ColoraStoreFinder';
import ImagesWithProductPointersMapper from './ImagesWithProductPointersMapper';
import LinkTilesMapper from './LinkTilesMapper';
import PromotionBannerMapper from './PromotionBannerMapper';
import TwoColumnQuoteMapper from './TwoColumnQuoteMapper';
import TwoColumnsWithImageOutOfBoundsMapper from './TwoColumnsWithImageOutOfBoundsMapper';
import { CAROUSEL_SETTINGS } from '../../../constants';
import CarouselMapper from '../CarouselMapper';
import ComponentMapper from '../ComponentMapper';

export type SectionMapperProps = {
  entry: SectionFields;
  theme: Theme;
  locale: string;
  className?: Nullable<string>;
  colors?: Color[];
  products?: IProduct[];
};

const SectionMapper = ({ entry, theme, locale, className, products, colors }: SectionMapperProps) => {
  if (entry.displaySectionAs === 'promotion-banner' || entry.displaySectionAs === 'promotion-banner-switched') {
    return <PromotionBannerMapper className={className} entry={entry} locale={locale} theme={theme} />;
  }

  if (entry.displaySectionAs === 'carousel') {
    const mappedEntries = entry.content?.reduce((prev: JSX.Element[], item, index) => {
      // Excludes corrupt entries
      if (!item.__typename) {
        return prev;
      }

      return [...prev, <CarouselMapper entry={item} key={`${item.id}-${index}`} locale={locale} theme={theme} />];
    }, []);

    return <Carousel options={CAROUSEL_SETTINGS}>{mappedEntries}</Carousel>;
  }

  if (entry.displaySectionAs === '2-columns-with-image-out-of-bounds') {
    return <TwoColumnsWithImageOutOfBoundsMapper entry={entry} locale={locale} theme={theme} />;
  }

  if (entry.displaySectionAs === '2-columns-quote') {
    return <TwoColumnQuoteMapper entry={entry} locale={locale} theme={theme} />;
  }

  if (entry.displaySectionAs === 'colora-store-finder') {
    return <ColoraStoreFinder entry={entry} locale={locale} theme={theme} />;
  }

  if (entry.displaySectionAs === 'link-tiles') {
    return <LinkTilesMapper entry={entry} locale={locale} theme={theme} />;
  }

  if (entry.displaySectionAs === 'images-with-product-pointers') {
    return (
      <ImagesWithProductPointersMapper
        colors={colors}
        entry={entry}
        locale={locale}
        products={products}
        theme={theme}
      />
    );
  }

  return (
    <>
      {entry.content?.map(item => (
        <ComponentMapper
          additionalProps={{ parent: entry.__typename, parentDisplay: entry.displaySectionAs }}
          entry={item}
          key={uuid()}
          locale={locale}
          theme={theme}
        />
      ))}
    </>
  );
};

export default SectionMapper;
