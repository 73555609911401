import { Widget as TypeformWidget } from '@typeform/embed-react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useTranslation } from 'next-i18next';

import { BossFormType, type FormFields } from '@boss/types/b2b-b2c';
import { Fallback } from '@boss/ui';
import { insertIntoArray } from '@boss/utils';

import { useAccount } from '../../../client-queries/account';
import { useFormField, usePointOfSale } from '../../../hooks';
import { getEnvironmentName, isB2b } from '../../../utils';
import { AdviceForm, BasicInfoForm, HelpForm, MachineForm, SpecialOrderForm } from '../../Form';
import { FORM_FIELD_KEYS as adviceFormKeys } from '../../Form/AdviceForm';
import B2BAdviceForm from '../../Form/B2BAdviceForm';
import B2BDecoDesignForm from '../../Form/B2BDecoDesignForm';
import B2BSamplesRequestForm from '../../Form/B2BSamplesRequestForm';
import { FORM_FIELD_KEYS as basicInfoFormKeys } from '../../Form/BasicInfoForm';
import ColorSupportLabForm from '../../Form/ColorSupportLabForm';
import { columns } from '../../Form/FormHelpers';
import FormWrapper from '../../Form/FormWrapper';
import MachineRepairForm from '../../Form/MachineRepairForm';
import NewsLetterForm from '../../Form/NewsLetterForm';
import SiteAssistanceForm from '../../Form/SiteAssistanceForm';
import TechnicalAdviceForm from '../../Form/TechnicalAdviceForm';
import { getFormMapperProps } from '../PropsMapper';

type Props = ReturnType<typeof getFormMapperProps>;
type BossPaintsFormType = FormFields['bossPaintsFormType'];
type ColoraFormType = FormFields['coloraFormType'];

const BossPaintsForms = ({ type, typeformId }: { type: BossPaintsFormType; typeformId: string | undefined }) => {
  const { t } = useTranslation('forms');
  const { clientnumber, firstname, lastname, email, orderreference } = useFormField();
  const formFieldsTranslations: Record<string, string> = t('fields', { returnObjects: true });

  const hiddenWithLoginHint = [
    BossFormType.TechnicalAdvice,
    BossFormType.ColorAdvice,
    BossFormType.MachineRental,
    BossFormType.MachineRepair,
    BossFormType.SiteAssistance,
    BossFormType.SamplesService,
  ];
  const hidden = [
    BossFormType.Return,
    BossFormType.ColorSupportLab,
    BossFormType.CustomDeco,
    BossFormType.PostPurchaseService,
    BossFormType.SpecialOrder,
  ];

  const determineFormVisibility = (type: BossFormType): 'showNothingWhenLoggedOut' | 'showLogin' | 'showAlways' => {
    if (hidden.includes(type)) {
      return 'showNothingWhenLoggedOut';
    }

    if (hiddenWithLoginHint.includes(type)) {
      return 'showLogin';
    }

    return 'showAlways';
  };

  const getFieldsForForm = () => {
    const hiddenFields: Array<string> = [];
    const extraFields: Array<string> = [];

    if (type === 'return') {
      extraFields.push('orderinfo', 'orderreference');
      hiddenFields.push('salutation', 'firstname', 'lastname');
    }

    let returnFields: Array<string> = [...basicInfoFormKeys];

    extraFields.forEach(field => {
      // since we want to add the added fields on the second to last place in the array, we loop them and add them with the insirtIntoArray function
      returnFields = insertIntoArray([...returnFields], returnFields.length - 1, field);
    });

    return returnFields.filter(field => !hiddenFields.includes(field));
  };

  switch (type) {
    case 'typeform':
      return typeformId ? <TypeformWidget id={typeformId} style={{ height: '100vh' }} /> : null;
    case 'general-contact':
      return (
        <FormWrapper behavior={determineFormVisibility(BossFormType.GeneralQuestion)}>
          <BasicInfoForm
            fieldsOverwrite={{ message: { label: formFieldsTranslations.questionGeneral ?? '' } }}
            type={type}
          />
        </FormWrapper>
      );
    case 'return':
      return (
        <FormWrapper behavior={determineFormVisibility(BossFormType.Return)}>
          <BasicInfoForm
            additionalFields={[
              {
                ...orderreference,
              },
              {
                name: 'orderinfo',
                type: 'textarea',
                label: formFieldsTranslations.retourinfo ?? '',
              },
            ]}
            fieldsOverwrite={{
              message: { label: formFieldsTranslations.questionReturn ?? '' },
              name: {
                required: true,
              },
            }}
            fieldsToShow={getFieldsForForm()}
            type={type}
          />
        </FormWrapper>
      );
    case 'color-advice': {
      return (
        <FormWrapper behavior={determineFormVisibility(BossFormType.ColorAdvice)}>
          <B2BAdviceForm type={type} />
        </FormWrapper>
      );
    }
    case 'samples-service':
      return (
        <FormWrapper behavior={determineFormVisibility(BossFormType.SamplesService)}>
          <B2BSamplesRequestForm type={type} />
        </FormWrapper>
      );
    case 'technical-advice': {
      return (
        <FormWrapper behavior={determineFormVisibility(BossFormType.TechnicalAdvice)}>
          <TechnicalAdviceForm type={type} />
        </FormWrapper>
      );
    }
    case 'color-support-lab':
      return (
        <FormWrapper behavior={determineFormVisibility(BossFormType.ColorSupportLab)}>
          <ColorSupportLabForm type={type} />
        </FormWrapper>
      );
    case 'site-assistance':
      return (
        <FormWrapper behavior={determineFormVisibility(BossFormType.SiteAssistance)}>
          <SiteAssistanceForm type={type} />
        </FormWrapper>
      );
    case 'custom-deco':
      return (
        <FormWrapper behavior={determineFormVisibility(BossFormType.CustomDeco)}>
          <B2BDecoDesignForm type={type} />
        </FormWrapper>
      );
    case 'post-purchase-service': {
      const subtitleTranslationsMap = {
        'technical-advice': formFieldsTranslations.questionHelpSubtitleTechnicalAdvice,
        'machinery-service': formFieldsTranslations.questionHelpSubtitleMachineryService,
        'color-support-lab': formFieldsTranslations.questionHelpSubtitleColorSupportLab,
        'site-assistance': formFieldsTranslations.questionHelpSubtitleSiteAssistance,
        'samples-service': formFieldsTranslations.questionHelpSubtitleSamplesService,
        'custom-deco': formFieldsTranslations.questionHelpSubtitleCustomDeco,
        'post-purchase-service': formFieldsTranslations.questionHelpSubtitlePostPurchaseService,
      };

      const questionMap = {
        'post-purchase-service': formFieldsTranslations.questionHelpPostPurchaseService,
      };

      return (
        <FormWrapper behavior={determineFormVisibility(BossFormType.PostPurchaseService)}>
          <HelpForm
            additionalFields={[
              { ...clientnumber },
              { ...firstname, disabled: true },
              { ...lastname, disabled: true },
              { ...email, disabled: true },
              { ...orderreference, required: false },
            ]}
            fieldsOverwrite={{
              companyname: {
                disabled: true,
              },
              email: {
                disabled: true,
              },
              question: {
                label: questionMap[type] ?? t('fields.question') ?? '',
                disclaimer: subtitleTranslationsMap[type],
              },
            }}
            fieldsToShow={[
              'clientnumber',
              'companyname',
              'firstname',
              'lastname',
              'email',
              'orderreference',
              'message',
              'termsandconditions',
            ]}
            type={type}
          />
        </FormWrapper>
      );
    }
    case 'machine-rental':
      return (
        <FormWrapper behavior={determineFormVisibility(BossFormType.MachineRental)}>
          <MachineForm type={type} />
        </FormWrapper>
      );
    case 'machine-repair':
      return (
        <FormWrapper behavior={determineFormVisibility(BossFormType.MachineRepair)}>
          <MachineRepairForm type={type} />
        </FormWrapper>
      );
    case 'special-order':
      return (
        <FormWrapper behavior={determineFormVisibility(BossFormType.SpecialOrder)}>
          <SpecialOrderForm type={type} />
        </FormWrapper>
      );
    default:
      return null;
  }
};

const ColoraForms = ({ type }: { type: ColoraFormType }) => {
  const { t } = useTranslation('forms');
  const { locale } = useRouter();
  const { data: account } = useAccount(locale ?? '');
  const formFieldsTranslations: Record<string, string> = t('fields', { returnObjects: true });
  const { stores } = usePointOfSale();
  const coloraStores = stores
    .filter(store => store.storetype === 'Colora')
    .map(store => ({ value: store.id, label: store.name }));

  coloraStores.unshift({ value: '', label: '' });

  const favoriteStoreId = stores.find(store => store.id === account?.preference?.shop)?.id;

  const storeField = {
    name: 'storeid',
    type: 'select' as const,
    initialValue: favoriteStoreId,
    options: coloraStores,
    label: t('fields.favoriteShop') ?? '',
  };

  switch (type) {
    case 'magazine': {
      const fieldKeys = adviceFormKeys.filter(key => key !== 'message');

      return (
        <AdviceForm
          additionalFields={[storeField]}
          fieldsToShow={insertIntoArray(fieldKeys, fieldKeys.length - 2, 'magazine')}
          type={type}
        />
      );
    }
    case 'skedify-color-advice': {
      return (
        <>
          <div
            className="pexip-engage-plugin"
            data-pexip-customer-timezone="Europe/Brussels"
            data-pexip-language="nl-BE"
          ></div>
          <Script src="https://colora.plugin.skedify.io/colora/pexip-engage-plugin.js" strategy="lazyOnload" />
        </>
      );
    }
    case 'search-painter': {
      return (
        <AdviceForm
          additionalFields={[storeField]}
          fieldsOverwrite={{
            message: { label: t('fields.messageSearchPainter') ?? '' },
            salutation: { colStyle: columns.ONE_THIRD },
            firstname: { colStyle: columns.ONE_THIRD },
            lastname: { colStyle: columns.ONE_THIRD },
          }}
          fieldsToShow={[
            'salutation',
            'firstname',
            'lastname',
            'email',
            'phonenumber',
            'storeid',
            'address',
            'street',
            'streetnumber',
            'bus',
            'zipcode',
            'city',
            'country',
            'message',
            'newsletter',
            'termsandconditions',
          ]}
          type={type}
        />
      );
    }

    case 'general-question':
      return (
        <BasicInfoForm
          fieldsOverwrite={{
            message: { label: formFieldsTranslations.questionGeneral ?? '' },
            salutation: { colStyle: columns.ONE_THIRD },
            firstname: { colStyle: columns.ONE_THIRD },
            lastname: { colStyle: columns.ONE_THIRD },
          }}
          fieldsToShow={['salutation', 'firstname', 'lastname', 'email', 'phonenumber', 'message']}
          type={type}
        />
      );

    case 'technical-advice': {
      return <AdviceForm additionalFields={[storeField]} type={type} />;
    }
    case 'newsletter': {
      return <NewsLetterForm type={type} />;
    }
    default:
      return null;
  }
};

const FormMapper = ({ channel, bossPaintsFormType, coloraFormType, typeformId }: Props) => {
  const formTypeMap = {
    'boss-paints': bossPaintsFormType,
    colora: coloraFormType,
  };

  if (!formTypeMap[channel]) {
    return null;
  }

  if (channel === 'boss-paints' && isB2b) {
    return <BossPaintsForms type={bossPaintsFormType} typeformId={typeformId} />;
  } else if (channel === 'colora' && !isB2b) {
    return <ColoraForms type={coloraFormType} />;
  }

  return <Fallback componentType="form" env={getEnvironmentName()} type="exclude" />;
};

export default FormMapper;
