import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faCircleInfo, faLeaf, faPercent, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faBadgePercent } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

import { productDetailPageBase } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { RichText } from '@boss/rich-text';
import { IProduct } from '@boss/services';
import { ISearchProduct } from '@boss/types/b2b-b2c';
import { slugify } from '@boss/utils';

import { ProductAverageReview } from '../../components';
import { COLOR_TYPE } from '../../constants/product';
import {
  convertColorCodeToId,
  isB2b,
  productCardVariant,
  productLabelVisibilityConfig,
  productListConfig,
} from '../../utils';

type Label = {
  icon?: IconDefinition;
  className?: string;
  children: ReactNode;
  iconClassName?: string;
};

export const useProductMap = () => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  const ACTION_STYLE = 'text-white bg-red-light' as const;

  const labelMap: { [key: string]: Label } = {
    'bossflash-action': {
      icon: faCircleInfo,
      className: ACTION_STYLE,
      children: t('product.bossflash-action'),
    },
    campaign: {
      icon: faCircleInfo,
      className: ACTION_STYLE,
      children: t('product.campaign'),
    },
    'colora-action': {
      icon: faBadgePercent,
      className: ACTION_STYLE,
      children: t('product.colora-action'),
    },
    bestseller: {
      icon: faPercent,
      className: 'text-textColor bg-white',
      children: t('product.bestseller'),
    },
    ecocheques: {
      icon: faLeaf,
      iconClassName: 'text-green',
      className: 'bg-white text-textColor shadow-sm',
      children: t('product.ecocheques'),
    },
    personal: {
      className: 'bg-yellow',
      children: t('product.personal'),
    },
    pallet: {
      className: 'bg-white',
      children: t('product.panel'),
    },
  };

  /**
   * Map product labels but keep the labelMap key order
   **/
  const mapProductLabels = (labels: (string | { type: string; description: string })[]): Label[] => {
    const labelKeys = labels
      .map(label => (typeof label === 'string' ? label : label.type))
      .map(key => key.toLowerCase());

    const processLabel = (key: string): Label | null => {
      if (productLabelVisibilityConfig[key] === 'hide' || !labelKeys.includes(key)) {
        return null;
      }

      const label = labels.find(label => typeof label !== 'string' && label.type?.toLowerCase() === key);
      const description = typeof label === 'object' ? label.description : undefined;

      /* For now we need to map the campaign - action label to a specific fixed label voor colora */
      if (key === 'campaign' && description?.toLowerCase() === 'actie' && !isB2b) {
        return { ...labelMap['colora-action'], children: t('product.colora-action') as string };
      }

      return description ? { ...labelMap[key], children: description } : labelMap[key];
    };

    return Object.keys(labelMap)
      .map(processLabel)
      .filter((label): label is Label => label !== null);
  };

  type MapProductSlugProps = {
    seoTitle?: string | null;
    colorId?: string;
    colorCode?: string;
    colorGroups?: string[];
  };

  type ConditionalMapProductSlugProps = MapProductSlugProps &
    ({ slug: string; productName?: string; id?: string } | { slug?: never; productName: string; id: string });

  const mapProductSlug = ({
    productName,
    id,
    seoTitle,
    colorId,
    colorCode,
    colorGroups,
    slug,
  }: ConditionalMapProductSlugProps) => {
    let basePath = slug ?? `${seoTitle ? slugify(seoTitle) : slugify(productName)}/${id}`;

    if (!basePath.startsWith(`/${productDetailPageBase}/`) && !basePath.startsWith(`${productDetailPageBase}/`)) {
      basePath = `/${productDetailPageBase}/${basePath}`;
    }

    if (!colorId && !colorCode && !colorGroups) {
      return basePath;
    }

    const queryParams = {
      colorId: colorId ?? convertColorCodeToId(colorCode ?? ''),
      colorGroupType:
        colorGroups?.includes(COLOR_TYPE.PREMIXED) && colorGroups?.includes(COLOR_TYPE.BASE)
          ? COLOR_TYPE.PREMIXED
          : colorGroups?.[0] ?? COLOR_TYPE.MYMX,
    };

    return `${basePath}?${new URLSearchParams(queryParams).toString()}`;
  };

  const mapProductComponent = (product: ISearchProduct | IProduct) => {
    const getTrackingCategoryName = (product?: IProduct | ISearchProduct) => {
      if (!product) {
        return undefined;
      } else if ('trackingInfo' in product) {
        return product?.trackingInfo?.categoryName;
      } else if (Array.isArray(product.categories)) {
        return product?.categories[0]?.name;
      }
    };

    return {
      title: product.name,
      id: product.id,
      slug: mapProductSlug({
        productName: product.name,
        id: product.id,
        seoTitle: product.seoTitle,
      }),
      description:
        product.details && !isB2b ? <RichText content={product.details} mapperOptions={{ locale }} /> : undefined,
      variant: productCardVariant,
      imageSrc: product.packshot?.src,
      price: product.fromPrice?.price,
      labels: product.labels ? mapProductLabels(product.labels) : undefined,
      renderRating: productListConfig.needsRatingRendered
        ? () => <ProductAverageReview elementSuffix="carousel" id={product.id} />
        : undefined,
      cartLabel: t('order'),
      addLabel: t('add'),
      plusIcon: faPlus,
      specifications: product.usps?.map((usp, index) => (
        <RichText
          content={usp}
          key={`${product.id}-specification-${index}`}
          mapperOptions={{ locale }}
          renderOptions={{ noParagraph: true }}
        />
      )),
      translations: {
        quantityPrefix: t('from') ?? '',
        quantitySufix: t('for') ?? '',
        strikePrice: t('originally') ?? '',
        disclaimer: t('product.price.fromPriceDisclaimer') ?? '',
      },
      trackInfo: {
        seoTitle: product.seoTitle ?? '',
        category: getTrackingCategoryName(product) ?? '',
      },
    };
  };

  return { mapProductLabels, mapProductComponent, mapProductSlug };
};

export default useProductMap;
