import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import type { MagazineFields } from '@boss/types/b2b-b2c';
import { MagazineCard } from '@boss/ui';

import MagazineCardModal from './Modal';
import { useSaveSubscription } from '../../../client-queries';
import { getMagazineCardProps } from '../PropsMapper';

const MagazineCardMapper = ({ entry, locale }: { entry: MagazineFields; locale: string }) => {
  const { t } = useTranslation();
  const { mutate: saveSubscription, isLoading: isSavingSubscription } = useSaveSubscription();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const translations = t('magazineCard', {
    returnObjects: true,
  });

  const handleSubmit = (values: {
    email: string;
    firstName: string;
    lastName: string;
    subscribeNewsletter: boolean;
  }) => {
    saveSubscription(
      {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        subscribeToNewsletter: values.subscribeNewsletter || undefined,
        gatedContentGuid: entry?.gatedContentFile?.id,
        successMessage: t('common:toast.magazineSubscription.success.title') as string,
      },
      {
        onSuccess: () => {
          setIsModalOpen(false);
        },
      },
    );
  };

  return (
    <>
      <MagazineCard
        onMailAction={() => {
          setIsModalOpen(true);
        }}
        {...getMagazineCardProps(entry, locale)}
        translations={translations as { mail: string }}
      />
      {isModalOpen && (
        <MagazineCardModal isLoading={isSavingSubscription} onCloseModal={setIsModalOpen} onSubmit={handleSubmit} />
      )}
    </>
  );
};

export default MagazineCardMapper;
