import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useDebounce } from 'usehooks-ts';

interface Props {
  value: number;
  min?: number;
  max?: number;
  onChange: (value: number) => void;
  className?: string;
  buttonClassName?: string;
}

const ButtonStyle = cva('rounded-full bg-white w-6 h-6 flex items-center justify-center', {
  variants: {
    disabled: {
      true: 'text-gray-300',
      default: 'text-gray-500 hover:bg-gray-light',
    },
  },
});

const Counter = ({ className, buttonClassName, value: count, min = 0, onChange: handleChange, max }: Props) => {
  const [internalValue, setInternalValue] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const debouncedValue = useDebounce(internalValue, 300);
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      handleChange(debouncedValue);
    } else {
      // Skip the first call to handleChange when component mounts
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    setInternalValue(count);
  }, [count]);

  const decrement = () => {
    setInternalValue(internalValue - 1);
  };

  const increment = () => {
    setInternalValue(internalValue + 1);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    if (value === '') {
      setInternalValue(value as unknown as number);
      return;
    }

    const numValue = Number(value);

    if (isNaN(numValue)) {
      return;
    }

    if (max !== undefined && numValue > max) {
      setInternalValue(max);
    } else if (numValue < min) {
      setInternalValue(min);
    } else {
      setInternalValue(numValue);
    }
  };

  const handleBlur = () => {
    if (typeof internalValue !== 'number' || internalValue < min) {
      setInternalValue(min);
    }
  };

  const disableDecrement = internalValue <= min;
  const disableIncrement = !!(max && internalValue >= max);

  return (
    <div className={twMerge('bg-button-quantity flex w-fit items-center gap-1 rounded-full p-0.5', className)}>
      <button
        className={twMerge(
          ButtonStyle({
            disabled: disableDecrement,
          }),
          buttonClassName,
        )}
        disabled={disableDecrement}
        onClick={decrement}
        type="button"
      >
        <FontAwesomeIcon icon={faMinus} size="xs" />
      </button>
      <input
        className="w-5 grow bg-transparent text-center outline-none"
        max={max}
        min={min}
        onBlur={handleBlur}
        onChange={handleInputChange}
        onFocus={() => inputRef.current?.select()}
        ref={inputRef}
        type="number"
        value={internalValue}
      />
      <button
        className={twMerge(
          ButtonStyle({
            disabled: disableIncrement,
          }),
          buttonClassName,
        )}
        disabled={disableIncrement}
        onClick={increment}
        type="button"
      >
        <FontAwesomeIcon icon={faPlus} size="xs" />
      </button>
    </div>
  );
};

export default Counter;
