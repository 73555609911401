/* eslint-disable sonarjs/cognitive-complexity */
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { Question, Quotation, Thing, VideoObject, WithContext } from 'schema-dts';
import { twMerge } from 'tailwind-merge';

import { RichText } from '@boss/rich-text';
import { IProduct } from '@boss/services';
import type { Color, Component, ImageFields, Theme, WidgetFields } from '@boss/types/b2b-b2c';
import {
  Accordion,
  ActionCard,
  Button,
  CampaignBanner,
  Image as CfImage,
  ColorTool,
  ContentTile,
  Cta,
  Fallback,
  Quote,
  Section,
  Video,
} from '@boss/ui';

import {
  ContactInformation,
  FormMapper,
  RecentlyViewedColors,
  getActionCardProps,
  getButtonProps,
  getCampaignProps,
  getCardWrapperProps,
  getContactInfo,
  getContentTileProps,
  getCtaProps,
  getFaqProps,
  getFormMapperProps,
  getImageProps,
  getQuoteProps,
  getRichTextProps,
  getSectionProps,
  getVideoProps,
} from '../../';
import { getEnvironmentName } from '../../../utils';
import { componentsToExclude } from '../../../utils/options';
import { ColorButton, ProductCard } from '../../Contentful';
import ContactForm from '../../Form/ContactForm';
import InspirationCard from '../../InspirationCard/InspirationCard';
import MagazineCardMapper from '../MagazineCardMapper';
type Props = {
  entry: Component;
  theme?: Theme;
  inline?: boolean;
  locale: string;
  additionalProps?: Record<string, unknown>;
  embeddedEntry?: boolean;
  colors?: Color[];
  products?: IProduct[];
  imageProps?: Record<string, unknown>;
};

const schemaString = 'https://schema.org';
/**
 * Dynamicly allocate schema.org data to the head
 */
const renderSchemaOrg = <T extends Thing>(schemaData: WithContext<T>) => (
  <Head>
    <script dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }} type="application/ld+json" />
  </Head>
);

const widgetMapper = (entry: WidgetFields, locale: string) => {
  if (entry.widgetType === 'color-tool') {
    return <ColorTool />;
  }

  if (entry.widgetType === 'corporate-contact-form') {
    return <ContactForm />;
  }

  if (entry.widgetType === 'viewed-colors') {
    return <RecentlyViewedColors />;
  }

  if (entry.widgetType === 'cookies-list') {
    return <div className="min-h-full" id="CookiebotDeclaration" />;
  }

  return null;
};

const imageMapper = (entry: ImageFields, additionalProps?: Record<string, unknown>) => {
  if (additionalProps?.parent === 'section') {
    return <CfImage {...getImageProps(entry)} {...additionalProps} className="max-h-1/2-screen min-h-full" />;
  }

  return <CfImage {...getImageProps(entry)} {...additionalProps} />;
};

// TODO: Consider a separate component for this, with a common interface
// and use CVA for formatting extra classes.
const EmbeddedEntryMapper = ({ entry, additionalProps, imageProps, ...rest }: Props) => {
  const type = entry?.__typename;

  if (type === 'image') {
    return (
      <span className={twMerge('h-112.5 my-5 block lg:my-12', imageProps?.wrapperClassName as string)}>
        <CfImage {...getImageProps(entry)} {...additionalProps} {...imageProps} />
      </span>
    );
  }

  return <Mapper additionalProps={additionalProps} entry={entry} {...rest} />;
};

const Mapper = ({
  entry,
  theme,
  locale,
  additionalProps,
  products,
  colors,
  inline,
  imageProps,
}: Props): JSX.Element | null => {
  const { t } = useTranslation();

  const type = entry.__typename;
  const columns = [
    '1-column',
    '2-columns',
    '3-columns',
    '4-columns',
    '2-columns-left-smaller',
    '2-columns-right-smaller',
    '2-columns-quote',
    '2-columns-33-right',
    '2-columns-33-left',
  ];

  if (componentsToExclude.includes(type)) {
    return <Fallback componentType={type} env={getEnvironmentName()} type="exclude" />;
  }

  if (type === 'section') {
    return (
      <Section
        {...getSectionProps(entry, theme, locale, additionalProps?.titleClassName, products, colors)}
        {...additionalProps}
      />
    );
  }

  if (type === 'richText') {
    return <RichText {...getRichTextProps(entry, theme, locale)} {...additionalProps} imageProps={imageProps} />;
  }

  if (type === 'cta') {
    if (
      !entry.internalLink &&
      !entry.externalLink &&
      !entry.internalFixedLink &&
      !entry.fileToDownload &&
      !entry.filteredEventOverviewLink
    ) {
      return null;
    }
    // Should a cta be rendered inside a section with columns, it should be shown as a cta
    if (columns.includes(additionalProps?.parentDisplay as string)) {
      return (
        <Cta
          className="flex flex-col items-center justify-center gap-4 p-4"
          {...getCtaProps(entry, theme, locale, inline)}
          {...additionalProps}
        />
      );
    }
    return <Button {...getButtonProps(entry, theme, locale, inline)} {...additionalProps} />;
  }

  if (type === 'image') {
    return imageMapper(entry, { ...additionalProps, ...imageProps });
  }

  if (type === 'video') {
    return (
      <>
        {renderSchemaOrg<VideoObject>({
          '@context': schemaString,
          '@type': 'VideoObject',
          name: entry.alternativeText,
          description: entry.alternativeText,
          uploadDate: entry.createdAt,
          '@id': entry.externalVideoUrl,
        })}
        <Video {...getVideoProps(entry)} {...additionalProps} />
      </>
    );
  }

  if (type === 'faq') {
    return (
      <>
        {renderSchemaOrg<Question>({
          '@context': schemaString,
          '@type': 'Question',
          text: documentToPlainTextString(entry.question),
          acceptedAnswer: {
            '@type': 'Answer',
            text: documentToPlainTextString(entry.answer.content),
          },
        })}
        <Accordion aria-label="faq" variant="primary" {...getFaqProps(entry, theme, locale)} {...additionalProps} />
      </>
    );
  }

  if (type === 'quote') {
    return (
      <>
        {renderSchemaOrg<Quotation>({
          '@context': schemaString,
          '@type': 'Quotation',
          text: entry.quote,
          spokenByCharacter: {
            '@type': 'Person',
            name: entry.author,
          },
        })}
        <Quote {...getQuoteProps(entry)} {...additionalProps} />
      </>
    );
  }

  if (type === 'product') {
    return <ProductCard id={entry.productID} {...additionalProps} />;
  }

  if (type === 'widget') {
    return widgetMapper(entry, locale);
  }

  if (type === 'campaign') {
    return <CampaignBanner {...getCampaignProps(entry, theme, locale)} />;
  }

  if (type === 'contentPage' || type === 'overviewPage') {
    return (
      <ContentTile
        {...getContentTileProps(entry, locale)}
        translations={{ linkLabel: t('goTo') }}
        whitespace={additionalProps?.parentDisplay === '1-column' ? 'none' : 'ratio'}
      />
    );
  }

  if (type === 'form') {
    return <FormMapper {...getFormMapperProps(entry)} />;
  }

  if (type === 'inspirationImage') {
    return <InspirationCard entry={entry} />;
  }

  if (type === 'contactInformation') {
    return <ContactInformation {...getContactInfo(entry, locale)} />;
  }

  if (type === 'action') {
    return <ActionCard {...getActionCardProps(entry, locale)} className="max-w-[30em]" />;
  }

  if (type === 'magazine') {
    return <MagazineCardMapper entry={entry} locale={locale} />;
  }

  if (type === 'cardWrapper') {
    return (
      <div className={entry.variation === 'wide' ? 'md:col-span-2' : ''}>
        <ContentTile
          {...getCardWrapperProps(entry, locale)}
          translations={{ linkLabel: t('goTo') }}
          whitespace={additionalProps?.parentDisplay === '1-column' ? 'none' : 'ratio'}
        />
      </div>
    );
  }

  if (type === 'color') {
    return (
      <span className={inline ? 'md:w-35 w-25 inline-block px-5 py-4' : ''}>
        <ColorButton id={entry.colorId} />
      </span>
    );
  }

  return <Fallback componentType={type} env={getEnvironmentName()} type="not-implemented" />;
};

const ComponentMapper = ({ inline, embeddedEntry, ...props }: Props) => {
  let body = null;

  if (embeddedEntry) {
    body = <EmbeddedEntryMapper {...props} />;
  } else {
    body = <Mapper {...props} inline={inline} />;
  }

  return inline ? <span className="inline">{body}</span> : body;
};

export default ComponentMapper;
