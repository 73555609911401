export const trackEvents = {
  ADD_TO_CART: 'add_to_cart',
  BEGIN_CHECKOUT: 'begin_checkout',
  FORM_SUBMITTED: 'form_submitted',
  KEYWORD_SEARCHED: 'keyword_searched',
  LINK_CLICKED: 'link_click',
  PAGE_VIEWED: 'page_view',
  PURCHASE: 'purchase',
  SEARCH_RESULT_SELECTED: 'search_result_selected',
  SELECT_ITEM: 'select_item',
  VIEW_ITEM: 'view_item',
  VIEW_LIST: 'view_item_list',
  WIZARD_INTERACTED: 'wizard_interacted',
};
