import { ArticleCard } from '@boss/ui';

import { useAccountPriceInfoByProductId } from '../../client-queries';
import { useProductMap } from '../../hooks';

const ArticleCardWrapper = ({
  hideLabels,
  ...props
}: React.ComponentProps<typeof ArticleCard> & { hideLabels?: boolean }) => {
  const { data: accountPriceInfo } = useAccountPriceInfoByProductId(props.article.productId, !hideLabels);
  const { mapProductLabels } = useProductMap();

  const labels = hideLabels ? [] : mapProductLabels(accountPriceInfo?.labels ?? []);

  return <ArticleCard {...props} labels={labels} />;
};

export default ArticleCardWrapper;
