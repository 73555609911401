import { ButtonProps } from '@boss/types/b2b-b2c';
import { faBookOpen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import ButtonOrAnchor from '../_Button';

const ButtonStyles = cva(
  'flex gap-3.75 w-fit h-fit items-center text-xl tracking-[-0.03em] rounded-[38px] px-6 py-1.5 transition duration-400 ease',
  {
    variants: {
      type: {
        primary: 'bg-brown-dark text-white hover:bg-beige-light hover:text-brown-dark',
        secondary:
          'text-brown-dark border-1 border-solid border-gray-light hover:bg-green-light hover:text-blue hover:border-green-light',
        tertiary: 'bg-yellow-soft text-base text-brown-dark hover:bg-yellow-soft/[0.4]',
        link: 'text-blue-dark text-base underline underline-offset-4 px-0 hover:text-blue',
        mobileMenu: '',
      },
      disabled: {
        true: 'pointer-events-none',
      },
    },
    compoundVariants: [
      {
        type: 'link',
        disabled: true,
        className: 'text-gray no-underline',
      },
      {
        type: ['primary', 'secondary', 'tertiary', 'mobileMenu'],
        disabled: true,
        className: 'bg-gray px-6 text-white no-underline',
      },
    ],
  },
);

const Button = ({
  icon,
  iconPosition = 'left',
  type,
  disabled,
  onClick,
  onKeyDown,
  className,
  href,
  label,
  alternativeText,
  hideLabel,
  testId,
  submitButton,
  anchorClassName,
  labelClassName,
  trackInfo,
  target,
}: ButtonProps) => (
  <ButtonOrAnchor
    alternativeText={alternativeText}
    anchorClassName={anchorClassName}
    className={twMerge(ButtonStyles({ type, disabled }), className)}
    disabled={disabled}
    href={href}
    onClick={onClick}
    onKeyDown={onKeyDown}
    submitButton={submitButton}
    target={target}
    testId={testId}
    trackInfo={trackInfo}
  >
    {icon && (iconPosition === 'left' || iconPosition === 'both') && <FontAwesomeIcon icon={icon} />}
    {!hideLabel && label && <span className={twMerge('w-full', labelClassName)}>{label}</span>}
    {icon && (iconPosition === 'right' || iconPosition === 'both') && <FontAwesomeIcon icon={icon} />}
    {!icon && type === 'tertiary' && <FontAwesomeIcon icon={faBookOpen} />}
  </ButtonOrAnchor>
);

export default Button;
